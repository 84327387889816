// pagination.service.ts
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  paginate<T>(items: T[], currentPage: number, itemsPerPage: number): T[] {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  }

  getTotalPages(totalItems: number, itemsPerPage: number): number {
    return Math.ceil(totalItems / itemsPerPage);
  }

  
  getPaginatedData<T>(data: T[], currentPage: number, itemsPerPage: number): T[] {
    return this.paginate(data, currentPage, itemsPerPage);
  }
  
  getPagesArray(totalPages: number): number[] {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
  
  scrollToTop(behavior: 'instant' | 'smooth' | 'auto',bodyscroll: 'bodyscroll' = null): void {
    if (bodyscroll == 'bodyscroll') {
     document.body.scrollTo({top:0,left:0,behavior:behavior}) 
     return;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (behavior == 'smooth') {
        window.scroll(0,0);        
      }
      else{
        window.scrollTo({behavior: 'instant'})
      }  
    }  
  }
    
}

