import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { dealerInquiriesPostDataInterface } from '../interfaces/request-interfaces/dealer-inquiries.interface';
import { country } from '../interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class DealerInquiriesService {
  private endpoint: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  postDealerInquiries(data: dealerInquiriesPostDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'dealer-inquiries', data);
  }
  dealerFormDropdowns(data: country): Observable<any> {
    return this.http.post<any>(this.endpoint + 'dealer-dropdown-option', data);
  }
  countryStateDropdown(data:any):Observable<any>{
    return this.http.post<any>(this.endpoint + 'countries-states', data)
  }
}
