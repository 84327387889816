import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-instructions',
    templateUrl: './instructions.component.html',
    styleUrls: ['./instructions.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, DatePipe]
})
export class InstructionsComponent implements OnChanges {
  domainImageBasePath: string = environment.domainImageBasePath;
  @Input() instructionsData: any[] = [];
  isVideoAvailable: boolean = false

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['instructionsData']?.currentValue) {
      this.instructionsData.forEach((instruction)=>{
        if (instruction.video_url.length > 0) {
          this.isVideoAvailable = true
        }else{
          this.isVideoAvailable = false
        }
      })
    }
  }

}
