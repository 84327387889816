import { ResolveFn } from '@angular/router';

export const productComponentResolver: ResolveFn<boolean | string> = (route, state) => {
  const id:string = route.params['id'];
  let skuName: string = route.params['skuName'];
  const pattern: boolean = /^[0-9]*$/.test(id.slice(1,id.length));
  if(id.length >1 && id.startsWith('p') && pattern) {
    let product_key = +id.slice(1,id.length);
    localStorage.setItem('selectedproductkey',String(product_key));
    skuName = skuName.replaceAll('%',' ');
    localStorage.setItem('checkSkuParam', String(skuName))
    return String(skuName);
  }
  else{
    return false;
  }
};
