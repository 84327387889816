<div class="subfooter" *ngIf="footerdata">
    <div class="container">
        <div class="subfooter-inner">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-center" [innerHTML]="menuLink"></p>
                </div>
            </div>
        </div>
    </div>
</div>