<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display:block">
                        <a name="pricinglisting"></a>
                        <div class="tab-pane fade active in mb-20" id="fp_tab1">
                            <div class="item_table_block ">
                                <table class="item_table " style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%;" class="pr_sku  d-none d-sm-table-cell sku-images">
                                            </th>
                                            <th style="width: 15%;" class="pr_sku">Item#</th>
                                            <th style="width: 10%;" *ngIf="inventryflag == 1">Status</th>
                                            <th style="width: 45%;" class="pr_descr">Description</th>
                                            <th style="width: 5%;" class="d-none d-sm-table-cell">Qty.</th>
                                            <th style="width: 10%;" class="msrp-price d-none d-sm-table-cell" *ngIf="!promotion && isMSRP"> MSRP Price </th>
                                            <th *ngIf="!promotion && isMapPricingPromo && !isMSRP" style="width: 1%;" class="d-none d-sm-table-cell"> </th>
                                            <th style="width: 10%;" class="d-none d-sm-table-cell">Price</th>
                                            <th *ngIf="!!promotion && !isMapPricingPromo" style="width: 10%; " class="d-none d-sm-table-cell ">Your Price</th>
                                            <th style="width: 10%; " class="d-none d-sm-table-cell "></th>
                                        </tr>
                                    </thead>
                                    <tbody class="isotope-container " style="display:contents;position:relative ">
                                        <ng-container>
                                            <ng-container *ngIf="productVariations && productVariations.length> 0;">
                                                <tr *ngFor="let prod of productVariations;  let i = index" class="sku_all isotope-item by_0">
                                                    <!-- 1 -->
                                                    <td class="d-none d-sm-table-cell sku-images">
                                                        <div class="app-sec">
                                                            <div class="owl-carousel content-slider-with-large-controls owl-theme" style="opacity: 1; display: block;">
                                                                <div class="owl-wrapper-outer" style="width: 123px; left: 0px;">
                                                                    <div class="owl-wrapper" style="width: 123px; left: 0px; display: block;">
                                                                        <div class="owl-item" style="width: 103px">
                                                                            <div class="overlay-container overlay-visible">
                                                                                <div class="overlay-container_outer">
                                                                                    <div class="overlay-container_inner">
                                                                                        <img [src]="!!prod?.product_variation_photo?.small_image ? cdnImagePath+prod?.product_variation_photo?.small_image: '../../../../../assets/images/category_landing_sf.png'" [title]="prod?.sku" class="img-responsive ls-is-cached lazyloaded">
                                                                                    </div>
                                                                                </div>
                                                                                <a 
                                                                                (click)="openImageModal(ImageContent,cdnImagePath+prod?.product_variation_photo?.small_image)"
                                                                                class="popup-img-sku2157 overlay-link nopadding plusicon" 
                                                                                [title]="prod.sku" 
                                                                                style="left: 20px; width: 60px;"
                                                                                >
                                                                                    <i class="fa fa-search-plus" style="left: 60px;"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <!-- 2 -->
                                                    <td>
                                                        <div>
                                                            <a [ngStyle]="{'position':'relative', 'z-index':'10'}" (click)="productRedirection(prod?.sku, prod?.product_key)">
                                                                {{prod?.sku}}
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <!-- 3 -->
                                                    <td *ngIf="inventryflag == 1">
                                                        {{ prod?.inventory_qty_on_hand > 0 ? 'In stock' : 'Out of stock' }}
                                                    </td>
                                                    <!-- 4 -->
                                                    <td>
                                                        <a (click)="productRedirection(prod?.sku, prod?.product_key)" class="sku-desc">
                                                            {{prod?.variation_name}}
                                                        </a>
                                                        <div class="d-block d-md-none">
                                                            <select class="order_quantity" [id]="prod?.product_variation_key+'qty'" [(ngModel)]="orderQuantity[prod.product_variation_key]" name="order_qty_24244">
                                                                <option class="It" value="1">1</option>
                                                                <option class="It" value="2">2</option>
                                                                <option class="It" value="3">3</option>
                                                                <option class="It" value="4">4</option>
                                                                <option class="It" value="5">5</option>
                                                                <option class="It" value="6">6</option>
                                                                <option class="It" value="7">7</option>
                                                                <option class="It" value="8">8</option>
                                                                <option class="It" value="9">9</option>
                                                                <option class="It" value="10">10</option>
                                                            </select>
                                                        </div>
                                                        <div class="d-block d-md-none msrp-price">
                                                            <ng-container *ngIf="prod?.msrp_price > 0 && isMSRP;">
                                                                $ <s>{{ prod?.msrp_price| decimal }}</s>
                                                            </ng-container>
                                                        </div>

                                                        <div class="d-block d-md-none total_price">
                                                            <ng-container *ngIf="promotion && !isMapPricingPromo else originalPricetemp">
                                                                $ <s>{{prod?.customer_price | decimal }}</s>
                                                            </ng-container>
                                                            <ng-template #originalPricetemp>
                                                                ${{prod?.customer_price | decimal }}
                                                            </ng-template>
                                                        </div>
                                                        <div class="d-block d-md-none" *ngIf="!!promotion && !isMapPricingPromo" class="total_price d-block d-md-none">
                                                            <p>${{ promotionalPrice[i] | decimal}}</p>
                                                        </div>
                                                        <div class="cust-addcart d-block d-md-none">
                                                            <ng-container>
                                                                <button 
                                                                *ngIf="+inventryflag == 1  && (prod?.inventory_qty_on_hand <= 0 && addToCartButtons[prod?.product_variation_key]) 
                                                                else otherbuttonref" 
                                                                (click)="onAddToCart(prod)" 
                                                                [id]="prod.product_variation_key" 
                                                                class="btn btn-cart btn-added-to-cart add_to_cart_checkout"
                                                                >
                                                                    PLACE BACK ORDER
                                                                </button>
                                                                <ng-template 
                                                                #otherbuttonref>
                                                                    <button 
                                                                    *ngIf="addToCartButtons[prod?.product_variation_key] && (  +inventryflag == 0 || prod?.inventory_qty_on_hand > 0) 
                                                                    else checkoutbuttonref" 
                                                                    (click)="onAddToCart(prod)" 
                                                                    [id]="prod.product_variation_key" 
                                                                    class="btn btn-cart btn-added-to-cart add_to_cart_checkout"
                                                                    >
                                                                        ADD TO CART
                                                                    </button>
                                                                    <div class="dropdown">
                                                                        <a class="dropbtn" id="myBtn">
                                                                            <i class="fa fa-caret-down btn btn-success"></i></a>
                                                                        <div id="myDropdown" class="dropdown-content">
                                                                            <li><a (click)="onAddToWishList(prod)" class="add_to_wishlist" [id]="prod.product_variation_key">Add To Wish List</a></li>
                                                                        </div>
                                                                    </div>
                                                                    <ng-template #checkoutbuttonref>
                                                                        <button 
                                                                        [routerLink]="['/cart']" 
                                                                        [id]="prod.product_variation_key" 
                                                                        class="btn btn-cart btn-added-to-cart add_to_cart_checkout" 
                                                                        [disabled]="disableCheckoutButton"
                                                                        >
                                                                            CHECKOUT NOW 
                                                                        </button>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                    <!-- 5 -->
                                                    <td class="d-none d-sm-table-cell" style>
                                                        <select class="order_quantity" [id]="prod?.product_variation_key+'qty'" [(ngModel)]="orderQuantity[prod.product_variation_key]" name="order_qty_24244">
                                                            <option class="It" value="1">1</option>
                                                            <option class="It" value="2">2</option>
                                                            <option class="It" value="3">3</option>
                                                            <option class="It" value="4">4</option>
                                                            <option class="It" value="5">5</option>
                                                            <option class="It" value="6">6</option>
                                                            <option class="It" value="7">7</option>
                                                            <option class="It" value="8">8</option>
                                                            <option class="It" value="9">9</option>
                                                            <option class="It" value="10">10</option>
                                                        </select>
                                                    </td>
                                                    <!-- 6 -->
                                                    <td class="msrp-price d-none d-sm-table-cell" *ngIf="!promotion && isMSRP ">
                                                        <ng-container *ngIf="prod?.msrp_price > 0 && isMSRP;">
                                                            $ <s>{{ prod?.msrp_price| decimal }}</s>
                                                        </ng-container>

                                                    </td>
                                                    <!-- 7 -->
                                                    <td class="hidden-xs" *ngIf="!promotion && isMapPricingPromo && !isMSRP">
                                                        &nbsp;
                                                    </td>
                                                    <!-- 8 -->
                                                    <td class="total_price d-none d-sm-table-cell">
                                                        <ng-container *ngIf="promotion && !isMapPricingPromo else originalPricetemp">
                                                            $ <s>{{prod?.customer_price | decimal }}</s>
                                                        </ng-container>
                                                        <ng-template #originalPricetemp>
                                                            ${{prod?.customer_price | decimal }}
                                                        </ng-template>
                                                    </td>
                                                    <!-- 9 -->
                                                    <td *ngIf="!!promotion && !isMapPricingPromo" class="total_price d-none d-sm-table-cell">
                                                        <p style="margin: 0;">${{ promotionalPrice[i] | decimal}}</p>
                                                    </td>
                                                    <!-- 10 -->
                                                    <td class=" d-none d-sm-table-cell">
                                                        <div class="cust-addcart">
                                                            <ng-container>
                                                                <button 
                                                                *ngIf="+inventryflag == 1  && (prod?.inventory_qty_on_hand <= 0 && addToCartButtons[prod?.product_variation_key]) 
                                                                else otherbuttonref" 
                                                                (click)="onAddToCart(prod)" 
                                                                [id]="prod.product_variation_key" 
                                                                class="btn btn-cart btn-added-to-cart add_to_cart_checkout"
                                                                >
                                                                    PLACE BACK ORDER
                                                                </button>
                                                                <ng-template 
                                                                #otherbuttonref>
                                                                    <button 
                                                                    *ngIf="addToCartButtons[prod?.product_variation_key] && (  +inventryflag == 0 || prod?.inventory_qty_on_hand > 0) 
                                                                    else checkoutbuttonref" 
                                                                    (click)="onAddToCart(prod)" 
                                                                    [id]="prod.product_variation_key" 
                                                                    class="btn btn-cart btn-added-to-cart add_to_cart_checkout"
                                                                    >
                                                                        ADD TO CART
                                                                    </button>
                                                                    <ng-template #checkoutbuttonref>
                                                                        <button 
                                                                        [routerLink]="['/cart']" 
                                                                        [id]="prod.product_variation_key" 
                                                                        class="btn btn-cart btn-added-to-cart add_to_cart_checkout" 
                                                                        [disabled]="disableCheckoutButton"
                                                                        >
                                                                            CHECKOUT NOW 
                                                                        </button>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="loadmore">
                                    <div class="hidden-xs ">
                                        <div class="cust-addcart ">
                                            <button *ngIf="hasMoreItems " (click)="loadMore()" class="btn btn-cart btn-added-to-cart add_to_cart_checkout">
                                                Load More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template
#ImageContent
>
    <div
    style="
    display: flex; 
    height: 10%; 
    width: 100%; 
    padding-right: 50px;
    margin-top: 40px;
    justify-content: flex-end;
    "
    >

        <img 
        style="
        background-color: white;
        border-radius: 50%;
        height: 40px;
        "
        (click)="onClickImageModalCancel()"
        src="../../../../../assets/images/cancel_button_img.png">
    </div>
    <div 
    style="display: flex; height: 100%; width: 100%; justify-content: center; align-items: center;"
    >
        <img 
        appClickOutside (clickedOutside)="onClickImageModalCancel($event)"
        [src]="openedImage">
    </div>
</ng-template>