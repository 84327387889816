<!--<div style="height: 70px;">
    <app-spinner *ngIf="isLoading" style="position: relative; top: 10px;"></app-spinner>
</div>-->

<div *ngIf="isLoading">
    <app-category-skeleton></app-category-skeleton>
</div>

<div *ngIf="!isLoading">
    <app-category-top-details [categoriesdetails]="topCategoryData" [categoryFilters]="categoryFilters" [subCategoriesCount]="subCategoriesCount" [subProductsCount]="subProductsCount" [universalProductsCount]="universalProductsCount" (applyFilterEvent)="applyFilter($event)"
        (applyShowAllEvent)="applyShowAll()" />
    <app-sub-category
    *ngIf="!isDabTemplate" 
    [subCategories]="subCategories" 
    [subProducts]="subProducts" 
    [universalProducts]="universalProducts" 
    [catProductPromotion]="productPromotion" 
    [name]="name" 
    [category_key]="category_key" 
    [limitSubCategories]="limitSubCategories" 
    [limitSubProducts]="limitSubProducts" 
    [hideCategoriesLoadMore]="hideCategoriesLoadMore" 
    [hideProductsLoadMore]="hideProductsLoadMore" 
    (refreshPageEvent)="refreshCategoryPage()" 
    (subCategoriesLoadMore)="loadMoreSubCategories($event)"
    (subProductsLoadMore)="laodMoreSubProducts($event)" 
    />
    
    <app-sub-category-dab
    *ngIf="isDabTemplate"
    [subCategories]="subCategories"
    [subProducts]="subProducts" 
    [universalProducts]="universalProducts" 
    [catProductPromotion]="productPromotion" 
    [name]="name" 
    [category_key]="category_key" 
    [limitSubCategories]="limitSubCategories" 
    [limitSubProducts]="limitSubProducts" 
    [hideCategoriesLoadMore]="hideCategoriesLoadMore" 
    [hideProductsLoadMore]="hideProductsLoadMore" 
    (refreshPageEvent)="refreshCategoryPage()" 
    (subCategoriesLoadMore)="loadMoreSubCategories($event)"
    (subProductsLoadMore)="laodMoreSubProducts($event)"
    />
    
    <app-subscribe/>

</div>