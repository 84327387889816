
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-wishlist-popup',
    templateUrl: './wishlist-popup.component.html',
    styleUrls: ['./wishlist-popup.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule]
})
export class WishlistPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<WishlistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
 
  closePopup(): void {
    this.dialogRef.close();
  }  
}
