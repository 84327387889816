<div class="header header-top mobile-menu d-none d-sm-block d-md-block d-lg-block uponscroll"> 
    <div class="container">
        <div class="row">
            <div class="col-md-3 pull-left hideOn768">
                <div *ngIf="showLogo" class="header-left clearfix">
                    <div id="logo" class="logo">
                        <a [routerLink]="['/']" class="rocket_logo_position ">
                            <img id="logo_img " [src]="storeImagePath+headerdata?.websiteHeader[firstObj+2]?.desktop_logo" alt="mcleodracing " class="img-responsive ">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-9 ">
                <div class="row ">
                    <div class="col-md-12 ">
                        <ul class="rocket_header_menu ">
                            <ng-container *ngIf="showFirstRowHeader ">
                                <li *ngFor="let header of headerdata.firstRow ">
                                    <a [routerLink]="[header.menu_link]" class="custom-class_118 button_box ">
                                        {{header.menu_name}}</a>
                                </li>
                            </ng-container>
                        </ul>
                        <div class="clearfix "></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-11 offset-md-1 header_ico_wra">
                        <div *ngIf="showWebsiteHeader">
                            <div *ngIf="headerdata?.websiteHeader[firstObj + 1]" class="ico-head mt-15">
                                <h5>{{ headerdata?.websiteHeader[firstObj + 1]?.website_slogan_header_text }}</h5>
                            </div>
                        </div>
                        <div *ngIf="showCorporateDropdown">
                            <app-associate-customer-list></app-associate-customer-list>
                        </div>
                        <div>
                            <div class="ico-list text-right" *ngIf="headerdata?.secondRow && showSecondRowHeader">
                                <ul *ngIf="showMyAccount" class="top_myacc">
                                    <style type="text/css ">
                                        .custom-class_144 {
                                            color: #000000 !important;
                                            background-color: #c8d2dc !important;
                                        }
                                        
                                        .custom-class_144:hover {
                                            color: #ffffff !important;
                                            background-color: #000000 !important;
                                        }
                                    </style>
                                    <li *ngFor="let secondRowMenu of headerdata?.secondRow">
                                        <ng-container *ngIf="secondRowMenu?.menu_name == 'My Account'" >
                                            <div *ngIf="username ">
                                                <a title="Welcome " class="custom-class_118 button_box " [routerLink]="[ '/edit-account'] " data-toggle="dropdown ">
                                                    Welcome, {{ username }}<i class="fa fa-user "></i>
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-right dropdown-animation cart compare-top-margin " id="compare-mobmenu ">
                                                    <div class="pt-1 text-right compare-dropdown ">
                                                        <a class="btn btn-group btn-gray btn-sm f-14 " style="margin-bottom: 12px; " [routerLink]="[ '/logout'] ">Logout</a>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div *ngIf="!username ">
                                                <a 
                                                [title]="secondRowMenu?.menu_name" 
                                                class="custom-class_118 button_box " 
                                                (click)="goToLogin() ">
                                                    {{ headerdata?.secondRow[firstObj]?.menu_name }} <i
                                                        class="fa fa-user "></i>
                                                </a>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="secondRowMenu?.menu_name == 'Search By Vehicle'">
                                            <div>
                                                <a
                                                (click)="openVechicleSearchPopup()"
                                                style="
                                                background-image: url('../../../assets/images/mcLeod_header_car_ico.png') !important;
                                                background-repeat: no-repeat !important;
                                                background-position: right 9px !important;
                                                padding-right: 40px;
                                                " 
                                                [title]="secondRowMenu?.menu_name"
                                                class="custom-class_144 button_box ">
                                                    {{secondRowMenu?.menu_name}} 
                                                </a>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="secondRowMenu?.menu_name != 'Search By Vehicle' && secondRowMenu?.menu_name != 'My Account' ">
                                            <div>
                                                <a
                                                [routerLink]="['/'+secondRowMenu?.menu_link]" 
                                                [title]="secondRowMenu?.menu_name"
                                                >
                                                    {{secondRowMenu?.menu_name}}
                                                </a>
                                            </div>
                                        </ng-container>

                                    </li>
                                </ul>
                                <ul class="social_icon_list ">
                                    <li class="phone ">
                                        <a href="tel:714-630-2764 " title="TOLL FREE: 714-630-2764 ">
                                            {{headerdata?.secondRow[firstObj]?.link_style_icon}}
                                            <i class="fa fa-user acc-icons "></i>
                                        </a>
                                    </li>
                                    <li class="dropdown " (mouseover)="onMouseHover() " (mouseleave)="onMouseLeave() ">
                                        <span id="dropdownMenuButton " aria-expanded="false " data-toggle="dropdown ">
                                            <i class="fa fa-search acc-icons "></i>
                                        </span>
                                        <ul class="dropdown-menu dropdown-menu-right dropdown-animation cart compare-top-margin " id="compare-mobmenu " [ngStyle]="{ 'display': dropdownSearchMenu} " #dropdownMenuSearchIcon>
                                            <div class="panel-body text-right compare-dropdown ">
                                                <a class="btn btn-group btn-gray btn-sm f-14 " style="margin-bottom: 12px; " id="dropdownMenuAnchor1 " (click)="onClickSearchByPartName() ">
                                                    Search By Part Name
                                                </a>
                                                <a class="btn btn-group btn-gray btn-sm f-14 " id="dropdownMenuAnchor2 " (click)="onClickSearchByPartHash() ">
                                                    Search By Part #
                                                </a>
                                            </div>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="cart-icon">
                                            <a [routerLink]="['/cart']">
                                                <i class="fa fa-shopping-cart acc-icons"></i>
                                                <span class="badge badge-warning pull-right cart-badge" matTooltip="cart total items: {{cartItems}}">
                                              {{cartItems}}
                                            </span>
                                            </a>
                                            <!-- Notification message -->
                                            <div *ngIf="showMessage" class="notification-message">
                                                Item added to cart!
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix "></div>
<div [ngStyle]="{ 'background-image': 'linear-gradient(#ecedf4, #e4e7ef, #ccd5df)', 'height': (searchByPartNameInput=='block' || searchByPartHashInput=='block' ) ? '40px' : '0' } ">
    <div class="custom-dropdown-input " [ngStyle]="{ 'display': searchByPartNameInput} ">

        <input type="search " class="form-c-search form-control " name="keyword " placeholder="Search By Part Name " [(ngModel)]="searchValueByPartName " (ngModelChange)="onInputvalueChangeByPartName() " #searchByPartNameDiv>
        <button type="submit " class="searchbtn ">
            <i class="fa fa-search " id="icon-search "></i>
        </button>
        <div class="insert_search1 " style="overflow:auto; box-shadow: 0 0 5px #363636; border: solid rgb(153, 153, 153) 1px; border-top: 0; background: rgb(255, 255, 255); ">
            <div *ngFor="let result of searchResultArrayByPartName " (click)="onClickSearchByPartNameResult(result.product_key,result.name) ">
                <a>{{result.name}}</a>
            </div>
        </div>
    </div>
    <div class="custom-dropdown-input " [ngStyle]="{ 'display': searchByPartHashInput} ">
        <div>
            <input type="text " class="form-c-search form-control " name="keyword " placeholder="Search By Part # " [(ngModel)]="searchValueByPartHash " (ngModelChange)="onInputvalueChangeByPartHash() " #searchByPartHashDiv>
            <button type="submit " class="searchbtn ">
                <i class="fa fa-search " id="icon-search "></i>
            </button>
            <div id="insert_search2" style="overflow:auto;box-shadow: 0 0 5px #363636; display: inline-block; border: solid rgb(153, 153, 153) 1px; border-top: 0;" #optionSearchByHash>
                <div *ngFor="let result of searchResultArrayByPartHash" (click)="onClickSearchByPartHashResult(result.product_key,result.product_variation_key,result.name,result.sku)">
                    <a>{{result.sku}} - {{result.name}}</a>
                </div>
            </div>
        </div>
    </div>