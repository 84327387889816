import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable , of} from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { HeadersDataInterface, SubCategoriesData } from '../interfaces/header.interface';
import { Common } from '../interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private endpoint: string = environment.apiUrl;
  private data: any;
  private cachedData$: Observable<any>;


  constructor(private http: HttpClient) {}

  headerList(): Observable<Common<HeadersDataInterface>> {
    const storedData = this.getStoredHeaderData();
    if (storedData) {
      return of(storedData);
    }

    if (!this.cachedData$) {
      this.cachedData$ = this.http.get< Common<HeadersDataInterface> >(this.endpoint+ "headers").pipe(
        tap(data => {
          this.storeHeaderData(data);
        }),
        shareReplay(1)
      );
    }
    return this.cachedData$;
  }

  private storeHeaderData(data: Common<HeadersDataInterface>): void {
    localStorage.setItem('headerData', JSON.stringify(data));
  }

  private getStoredHeaderData(): Common<HeadersDataInterface> | null {
    const data = localStorage.getItem('headerData');
    return data ? JSON.parse(data) : null;
  }

  headerNavList() : Observable< Common<HeadersDataInterface> > {
    return this.http.get< Common<HeadersDataInterface> >(this.endpoint+ "headers");
  }

  cartList() : Observable< Common<HeadersDataInterface> > {
    return this.http.get<Common<HeadersDataInterface>>(this.endpoint+ "headers");
  }

  submitYourRidedata(userData: any): Observable<any>  {
    return this.http.post(this.endpoint+'submit_your_ride', userData);
  }

  headerNavCategoriesSubCategories(): Observable< Common<SubCategoriesData> >{
    return this.http.get< Common<SubCategoriesData> >(this.endpoint+'categories-subcategories');
  }

  getDropdownData(): Observable<any> {
    return this.http.get(this.endpoint + 'dealers-list');
  }

  getloginPageimage(): Observable<any> {
    if (this.data) {
        return of(this.data);
    } else {
        return this.http.get(this.endpoint + 'headers').pipe(
          tap(response => {
            this.data = response;
          })
        );
      }
  }

}
