import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { CommunicationService } from 'src/app/service/communication.service';
import { NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-product-size',
    templateUrl: './product-size.component.html',
    styleUrls: ['./product-size.component.css'],
    standalone: true,
    imports: [NgClass, NgFor],
})
export class ProductSizeComponent implements OnInit, OnChanges {
  firstObj: number = this.appConstants.firstObj;
  product_key: any;
  @Input() productSize: any[] = [];
  @Input() variationCount: number = 0;
  @Input() isColorSelected: boolean = null;
  @Input() isOptionSelected: boolean = null;
  @Input() selectedSize: string;

  @ViewChild('applicationsTab') applicationsTab: ElementRef;
  
  @Output() selectedProductSizeEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private appConstants: AppConstants,
    public comm: CommunicationService
  ){}

  ngOnInit(): void {
    this.product_key = localStorage.getItem('selectedproductkey');
    this.setSelectedSize('All');
    this.getUniqueSizes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isColorSelected']?.currentValue || changes['isOptionSelected']?.currentValue){
      if (this.isColorSelected || this.isOptionSelected) {
        this.setSelectedSize(null);
      }
    }
    if (changes['selectedSize']?.currentValue == 'All' && !changes['selectedSize'].isFirstChange()) {
      this.onSizeClick('All');
    }
  }


  getUniqueSizes(): any[] {
    const uniqueSizes = [];

    for (let size of this.productSize) {
      if (!uniqueSizes.some((uniqueSize) => uniqueSize.size === size.size)) {
        uniqueSizes.push(size);
      }
    }
    return uniqueSizes;
  }

  onSizeClick(size: string) : void {
    this.setSelectedSize(size);
    this.sendSizeData();
  }

  setSelectedSize(size: string) : void{
    this.selectedSize = size;
  }

  sendSizeData(): void{
    this.selectedProductSizeEvent.emit(this.selectedSize)
  }

  scrollToApplicationsTab(): void{
    if (this.applicationsTab && this.applicationsTab.nativeElement) {
      this.applicationsTab.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
}
