import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { ProductService } from 'src/app/service/product.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-included-component',
    templateUrl: './included-component.component.html',
    styleUrls: ['./included-component.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class IncludedComponentComponent implements OnChanges{
  firstObj: number = this.appConstants.firstObj;


  @Input() selectedProductVariationKey: number = null;
  masterProductDeatils: any[] =[]; 
  


  constructor(
    private appConstants: AppConstants,
    private productHttp: ProductService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    (async()=>{
      if (changes['selectedProductVariationKey']?.currentValue) {
        const data = this.productHttp.getMasterProductsDetails(this.selectedProductVariationKey);
        this.masterProductDeatils = data;
        if (data.length == 0) {
        await this.fetchMasterProducts();
        } 
      }
    })();
  }

  async fetchMasterProducts(){
    return new Promise<null>((resolve,reject)=>{
      this.productHttp.masterProductVariations(this.selectedProductVariationKey).subscribe(
        {
          next:(response) =>
          {
            if(response[this.firstObj]?.success == true){
              const data = response[this.firstObj]?.data;
              this.masterProductDeatils = data.masterVariation[this.firstObj]?.product_variation_associated;
              this.productHttp.setMasterProductsDetails(this.selectedProductVariationKey,this.masterProductDeatils);
              resolve(null)
            }
            else{
              reject(null)
            }    
          },
          error:(error: Error) => 
          {
            // console.error('Custom Error: Unexpected Error while fetching masterProducts', error);
            reject(null);    
          },
        }
      )
    })
  }


}

