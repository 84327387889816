<div class="product-tabs-content section_data col-md-8 col-sm-8 mt-20" style="padding-left: 0;">
    <h3>Product Options</h3>
    <table 
    class="table data-table" 
    border="1"
    >
        <tbody>
            <ng-container 
            *ngFor="let optionGroup of productOptionsDisplay;let i= index"
            >
                <!-- Display the option_group_name -->
                <tr class="odd">
                    <th class="text-left" style="border-bottom: none;">
                        {{ optionGroup.option_group_name }}
                        <span 
                        *ngIf="optionGroup.is_required == 1" 
                        style="color:#e51937;"
                        >
                            *
                        </span>
                    </th>
                </tr>
                <!-- Iterate over product_option array and display options -->
                <tr class="odd">
                    <td class="text-left">
                        <select 
                        class="form-control product_option_group" 
                        [name]="'product_option_'+optionGroup?.product_option_group_key" 
                        [id]="'product_option_'+optionGroup?.product_option_group_key" 
                        [(ngModel)]="groups[optionGroup?.product_option_group_key]"
                        (ngModelChange)="onOptionChange($event,optionGroup?.product_option_group_key,i)"
                        >

                        <option value="" class="custom">Select an option</option>

                        <ng-container 
                        *ngFor="let option of optionGroup.product_option"
                        >
                        <option 
                        [value]="option.product_option_key"
                        >
                            <!-- Display option based on its type -->
                            <ng-container 
                            *ngIf="option.option_type === 'enter_option_name'"
                            >
                                {{ option.option_name }} ({{ option.price_adjustment }} ${{ (+option.price * +option.add_to_cart_qty | decimal)  }})
                            </ng-container>

                            <ng-container 
                            *ngIf="option.option_type === 'select_product_sku'"
                            >
                                {{ option.option_sku_name }}-{{ option.assigned_sku }} ({{ option.price_adjustment | titlecase }} {{'$'+(+option.add_to_cart_qty * +option?.price | decimal) || '' }})
                            </ng-container>
                            
                            <ng-container 
                            *ngIf="option.option_type === 'replace_product_sku'"
                            >
                            <ng-container *ngIf="option?.option_sku_name?.length > 0 || option?.option_name?.length > 0  else showOptionAssignedSku">
                                {{ option.option_sku_name }}-{{ option.assigned_sku }}
                            </ng-container >
                            <ng-template #showOptionAssignedSku>
                                {{option.assigned_sku}}
                            </ng-template>
                            </ng-container>
                        </option>
                        </ng-container>
                    </select>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div> 