import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JwtService } from './jwt.service';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AuthHttpService } from './auth-http.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  popupOpened: boolean = true;
  modalDialog:any;
  constructor(
    private jwt: JwtService,
    private router: Router,
    private authService: AuthHttpService,
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    const token = this.jwt.getTokenFromLocalStorage();
    request = request.clone({
      headers: request.headers
        .set('Authorization', 'Bearer ' + token)
        .set('Strict-Transport-Security', 'max-age=60000'),
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

         // event = event.clone({ body: this.actionInterceptor(event.body) });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              return this.authService.accessToken$.pipe(
                first(),
                switchMap((accessToken) => {
                  request = request.clone({
                    setHeaders: {
                      authorization: `Bearer ${accessToken}`,
                    },
                  });
                  return next.handle(request);
                })
              );
            }),
            catchError((err) => {
              // Redirect the user to the login page
              this.router.navigate(['/dashboard/logout']);
              return throwError(err);
            })
          );
        }

        return throwError(error);
      })
    );
  }
}
