<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display:block">
                        <a name="pricinglisting"></a>
                        <div class="tab-pane fade active in mb-20" id="fp_tab1">
                            <div class="item_table_block ">
                                <div class="row" style="margin: 0;">
                                    <div class="alert-danger mt-0 reviewcommt_block" style id="loggedin">
                                        <div class="panel-group collapse-style-1" id="accordion-faq">
                                            <div *ngFor="let que of faqsData" class="panel panel-default">
                                                <div class="panel-heading" [id]="que?.product_faqs_key">
                                                    <h4 class="panel-title" [id]="'title_'+que.product_faqs_key">
                                                        <a data-toggle="collapse" data-parent="#accordion-faq" class="collapsed" (click)="onQuestionClick(que.product_faqs_key)" [innerHTML]="que?.question">
                                                            <strong></strong><br />
                                                            <br />
                                                        </a>
                                                    </h4>
                                                    <div *ngIf="selectedQuestionId === que.product_faqs_key">
                                                        <hr style="border-top:1px solid #600505;margin: 0;">
                                                        <h4 class="panel-title" id="title_{{que.product_faqs_key}}">
                                                            <a data-toggle="collapse" data-parent="#accordion-faq" class="collapsed" [innerHTML]="que?.answer">
                                                                <strong></strong><br />
                                                                <br />
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </div>
                                                <!-- <div id="collapse386" class="panel-collapse collapse"
                                                    aria-expanded="false" style="height: 0px;" *ngIf="isAccordionOpen" [@slideInOut]="'open'" class="accordion-content"
                                                    innerHTML="{{que.answer}}">
                                                    <div class="panel-body">
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  