import { Component } from '@angular/core';

@Component({
    selector: 'app-product-details-skeleton',
    templateUrl: './product-details-skeleton.component.html',
    styleUrls: ['./product-details-skeleton.component.css'],
    standalone: true
})
export class ProductDetailsSkeletonComponent {

}
