import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-product-specifications',
    templateUrl: './product-specifications.component.html',
    styleUrls: ['./product-specifications.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class ProductSpecificationsComponent implements OnChanges{
  firstObj: number = this.appConstants.firstObj;

  @Input() selectedSkuSpecifications: any[] = [];
  specificationsdata: any[] = []

  constructor(private appConstants: AppConstants) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['selectedSkuSpecifications']?.currentValue) {
      this.getSpecificationslabelandValues();
    }
  }

  getSpecificationslabelandValues(){
    const data = this.selectedSkuSpecifications[this.firstObj];
    let keys = Object.keys(data);
    let values  = Object.values(data);
    if (keys.length > 2) {
      keys = keys.slice(2,keys.length);
      values = values.slice(2,values.length);
      for(let i = 0; i < values.length ; i++){
        const value = values[i];
        if (String(value).length > 0) {
          this.specificationsdata.push(value);
        }
      }
    }
  }
}
