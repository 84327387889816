import { ViewportScroller, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { ProductService } from 'src/app/service/product.service';
import { environment } from 'src/environments/environment.prod';
import { NgImageSliderModule } from 'ng-image-slider';


@Component({
    selector: 'app-related-products',
    templateUrl: './related-products.component.html',
    styleUrls: ['./related-products.component.css'],
    standalone: true,
    imports: [NgIf, NgImageSliderModule]
})
export class RelatedProductsComponent implements OnInit,OnChanges{
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath: string = environment.cdnImagePath;
  @Input() productKey: number | null = null;
  productData: any[] = [];
  relatedproduct: [];
  proimage: any;
  imagepath: any;                                                      
  imageObject: any[] = [];
  loading: any;
  error: any;


  constructor(
    private appConstants: AppConstants,
    private ProductService:ProductService,
    private viewportScroller: ViewportScroller,
    private router: Router
  ){}
  
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['productKey']?.isFirstChange() && changes['productKey']?.currentValue) {
      this.fetchProductData(this.productKey);  
    }
  }
  fetchProductData(productKey: number): void {
    this.loading = true;
    this.ProductService.getProductRelationsByProductKey(productKey)
      .subscribe(
        {
          next:(response) => 
          {
            if (response[this.firstObj].success == true) {
              this.relatedproduct = response[this.firstObj]?.data || [];
              this.proimage = this.relatedproduct;
              this.imageObject = [];
  
              for(let i = 0; i < this.proimage?.length; i++){
                const mediumImage = this.proimage[i]?.related_products?.product_photo.medium_image;                
                this.imagepath = !!mediumImage && mediumImage.length > 0 ? this.cdnImagePath + mediumImage : '../../../../../assets/images/category_landing_sf.png';
                const imageItem = {
                  image: this.imagepath,
                  thumbImage: this.imagepath,
                  title: this.proimage[i]?.related_products?.name || null,
                  productKey: this.proimage[i]?.related_products?.product_key
                };
                this.imageObject.push(imageItem);              
            }
            }else{
              this.relatedproduct = [];
            }                  
          },
          error:(error: Error) => 
          {
            //later log it into a file
            console.error('Custom Error: Unexpected Error while getting roduct-relations-by-product_key',error);
          }
        }
      );
  }

  onSelectProduct(newProductKey: number): void {
    localStorage.setItem('selectedProductKey', newProductKey.toString());
    console.log('Updated selectedProductKey in localStorage:', newProductKey); 
    this.productKey = newProductKey;
    this.fetchProductData(newProductKey);
  }

  productRedirection(index: number): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    console.log('this.imageObject[index]',this.imageObject[index])
    const product_key = this.imageObject[index]?.productKey
    console.log(product_key)
    let name: string = this.imageObject[index]?.title;
    name = name.replaceAll(' ','-');
    localStorage.setItem('selectedproductkey',String(product_key));
    this.router.navigate(['/'+name,'p'+product_key]);    
  }
}


