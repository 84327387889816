<div class="modal-dialog modal-sm">
  <div class="modal-content" style="padding: 20px;">
    <div class="modal-header1">
      <div style="overflow: hidden;clear: both">
        <button type="button" class="close" style="float: right" data-dismiss="modal" (click)="closePopup()">×</button>
      </div>
    </div>
    <div class="sa-icon sa-success animate" style="display: block;">
      <span class="sa-line sa-tip animateSuccessTip"></span>
      <span class="sa-line sa-long animateSuccessLong"></span>
      <div class="sa-placeholder"></div>
      <div class="sa-fix"></div>
    </div>
    <h2 class="formateH2">Notifications!</h2>
    <div>
      <h4>selected customer is not found please try with another customer and create a support issue
</h4>
    </div>

    <div class="sa-button-container" style="text-align: center;">
      <button class="cancel" tabindex="2" style="display: none;">Cancel</button>
      <button class="confirm" tabindex="1" class="btn btn-lg btn-gray-transparent"
        style="background-color: rgb(198, 32, 38);" (click)="closePopup()">OK</button>
    </div>
  </div>
</div>
