import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { searchByVehicleDropDownPostDataInterface, searchByVehiclePostDataInterface } from '../interfaces/request-interfaces/vehicle-search.interface';
import { CommunicationService } from './communication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class SearchByVehicleService {
  private endpoint: string = environment.apiUrl;
  
  constructor(
    private http: HttpClient,
    private comm : CommunicationService,
    private router: Router,
  ) {}

  getSearchByVehicleResults(data:searchByVehiclePostDataInterface): Observable<any>{
    return this.http.post<any>(this.endpoint+'search-by-vehicle-results',data);
  }


  getDropDownDataSearchByVechicles(data: searchByVehicleDropDownPostDataInterface):Observable<any>{
    return this.http.post(this.endpoint+'search-by-vehicle',data);
  }

  clearVehicleSearch(){
    this.comm.vehiclenameSubject.next('');
    localStorage.removeItem('vehiclename');
    if (localStorage.getItem('vehiclesearchForm') != undefined) {
      localStorage.removeItem('vehiclesearchForm');
      if (this.router.url == '/vehicle-search') {
        this.router.navigate(['/']);
      }
      
    }
    localStorage.removeItem('vehiclesearchData');
  }

  isSearchVehicleExists(): boolean{
    let vehicleName = localStorage.getItem('vehiclename') || null;
    return !vehicleName ? false : true;  
  }

  getVechicleName(): string{
    const vehicleName = localStorage.getItem('vehiclename') || null;
    return !vehicleName ? null : vehicleName;
  }

}
