import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';

import { AppConstants } from 'src/app/app.constants';

import { ProductService } from 'src/app/service/product.service';
import { CartService } from 'src/app/service/cart.service';
import { environment } from 'src/environments/environment.prod';
import { Router, RouterLink } from '@angular/router';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { wishlistPostDataInterface } from 'src/app/interfaces/request-interfaces/wishlist-popup.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '../../../../pipes/decimal.pipe';
import { ClickOutsiteDirective } from '../../../../directive/click-outsite.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgStyle,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        FormsModule,
        RouterLink,
        ClickOutsiteDirective,
        DecimalPipe,
    ],
})
export class ApplicationComponent implements OnChanges{
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath = environment.cdnImagePath;
  productVariations: any[] = [];
  order_key: number= null;
  offset: number = 0;
  limit: number = 10;
  hasMoreItems: boolean = true;
  promotionalPrice: number[] = [];
  promotion : number = null;
  inventryinfo: string;
  isMSRP: boolean = false;
  orderQuantity: {[variation_key: number| string] : number} = {};
  addToCartButtons: {[variation_key: number]: boolean} = {};
  inventryflag:number;
  openedImage: string = '';
  firstClicked: boolean;
  @Input() product_key: number;
  @Input() productName: string = ''
  @Input() selectedProductVariationKey: number = null;
  @Input() selectedSize: string = null;
  @Input() variationCount: number = 0;
  @Input() productPromotion:any;
  @Input() disableCheckoutButton: boolean = false;
  @Output() disdisableCheckoutButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendSingleVariationDataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendSamePriceEvent: EventEmitter<number> = new EventEmitter<number>();

  isMapPricingPromo: boolean = false;
  promotionType: string = null;

  constructor(
    private appConstants: AppConstants,
    private productHttp: ProductService,
    private cartService: CartService,
    private commonService: CommanpageService,
    private router: Router,
    private comm: CommunicationService,
    private dialogRefImagePopup: NgbModal
  ){
  }

  ngOnChanges(changes: SimpleChanges): void {
    (async ()=>{
      if(changes['product_key']?.currentValue){
        await this.setProductVariations();
        this.setInitialOrderQuantityandAddToCartQuantity();
        this.getOrderQuantityandAddToCartQuantityFromOrderItemsList();
        this.displayMSRPPrice();
      }
      else if(changes['selectedSize']?.currentValue) {
        if (this.product_key) {
          await this.setProductVariations();
          this.setInitialOrderQuantityandAddToCartQuantity();
          this.getOrderQuantityandAddToCartQuantityFromOrderItemsList();
        }
      }
      this.calculatePromotion();
      if (this.productVariations.length == 1) {
        this.sendSingleVariationDataEvent.emit(
          {
            promotionalPrice: +this.promotionalPrice[this.firstObj] || null,
            price: +this.productVariations[this.firstObj].customer_price,
            selectedProductVariationKey: +this.productVariations[this.firstObj].product_variation_key,
            selectedSku: this.productVariations[this.firstObj].sku,
            isSelectedSkuInventoryAvailable: this.productVariations[this.firstObj].inventory_qty_on_hand > 0
          }
        );
      }
    })();
  }

  fetchProductVariations(size: string){
    return new Promise<null>((resolve,reject)=>{
      const data = {
        size: ''
      }
      this.productHttp.productVariations(this.product_key,this.offset,data).subscribe(
        {
          next:(response) =>
          {
            console.log('variation application data:',response);
            if(response[this.firstObj]?.success == true){
              if (this.variationCount >= 10) {
                this.hasMoreItems = true;
              }
              else{
                this.hasMoreItems = false
              }
              const data = response[this.firstObj]?.data?.all_product_variations;
              this.inventryflag = +response[this.firstObj]?.data?.inventory_flag[this.firstObj].inventory;
              this.productVariations = this.productVariations.concat(data);
              resolve(null);
            }
            else{
              this.offset > 0 ? this.hasMoreItems = false && resolve(null) : reject(null);
            }
          },
          error:(error: Error) =>
          {
            //later log it into a file
            // console.error('Custom Error: Unexpected Error while fetching product variations', error);
            this.offset > 0 ? this.hasMoreItems = false && resolve(null) : reject(null);
          },
        }
      )
    })
  }

  fetchProductVariationsBySize(){
    return new Promise<null>((resolve,reject)=>{
      const data = {
        size: this.selectedSize
      }
      this.productHttp.productVariations(this.product_key,0, data).subscribe(
        {
          next:(response) =>
          {
            if(response[this.firstObj]?.success == true){
              const data = response[this.firstObj]?.data;
              this.productVariations = data?.all_product_variations;
              resolve(null)
            }
            else{
            reject(null);
            }
          },
          error:(error: Error) =>
          {
            console.error('Custom Error: Unexpected Error while fetching product variations by size',error);
            reject(null);
          },
        }
      )
    })
  }

  async setProductVariations(){
    if(this.selectedSize == 'All'){
      // this.productHttp.initializeAllProductDetailsInfoOfApplication()
        this.variationCount <= 10 ? this.hasMoreItems = false : this.hasMoreItems = true;
        this.productVariations = [];
        await this.fetchProductVariations('');
    }
    else{
      //set different size data here
      this.hasMoreItems = false;
      await this.fetchProductVariationsBySize();

    }
  }

  setInitialOrderQuantityandAddToCartQuantity(){
    this.orderQuantity = {};
    for(let prod of this.productVariations){
      let variation_key = prod.product_variation_key
      this.orderQuantity[variation_key] = 1;
      this.addToCartButtons[variation_key] = true;
    }
  }

  getOrderQuantityandAddToCartQuantityFromOrderItemsList(){
    const orderItemsList = this.cartService.getOrderItemsList();
    const variationKeys = Object.keys(this.orderQuantity);
    if (!!orderItemsList) {
      for(let key of variationKeys){
        this.orderQuantity[key] = !!orderItemsList[key] ? orderItemsList[key]: 1;
        this.addToCartButtons[key] = !!orderItemsList[key] ? false: true;
      }
    }
  }

  async loadMore(){
    this.offset += this.limit;
    await this.fetchProductVariations('');
    this.setInitialOrderQuantityandAddToCartQuantity();
    this.getOrderQuantityandAddToCartQuantityFromOrderItemsList();
    this.displayMSRPPrice();
    this.calculatePromotion();
  }


  async onAddToCart(product: any){
    this.order_key = +localStorage.getItem('orderId') || null;
    this.disableCheckoutButton = true;
    this.disdisableCheckoutButtonEvent.emit(this.disableCheckoutButton);
    localStorage.setItem('ItemRecentlyAdded', 'true');
    await this.cartService.addToCart(product, this.order_key, this.orderQuantity[product.product_variation_key],product.calculated_price, product.promotion_key, false)
    this.comm.itemCount.next(null);
    this.disableCheckoutButton = false;
    this.disdisableCheckoutButtonEvent.emit(this.disableCheckoutButton);

    this.addToCartButtons[product.product_variation_key] = false;
  }

  async onAddToWishList(product:any){
    const data: wishlistPostDataInterface = {
      qty: this.orderQuantity[product.product_variation_key],
      calculated_price: product.calculated_price,
      product_variation_key: this.orderQuantity[product.product_variation_key],
      save_for_later: false
    }

    this.productHttp.wishlist(data).subscribe(
      {
        next:(response) =>
        {
          if (response[this.firstObj]?.success == true) {
          }
          else{
            console.error("Custom Error: Unexpected Error - Product didn't get added to wishlist");
            this.router.navigate(['/customer-login']);
          }
        },
        error:(error: Error) =>
        {
          console.error("Custom Error: Unexpected Error - Product didn't get added to wishlist");
          this.router.navigate(['/customer-login']);
        },
      }
    );
  }

  calculatePromotion() : void {
    this.isMapPricingPromo = this.productPromotion[this.firstObj]?.data?.[this.firstObj]?.is_map_pricing_promo == "1";
    if (!this.isMapPricingPromo) {
      this.promotion = +this.productPromotion[this.firstObj]?.data[this.firstObj]?.discount || 0;
      const variations = this.productVariations;
      this.promotionType = this.productPromotion[this.firstObj]?.data?.[this.firstObj]?.promotion_type;
      if (this.promotionType == "percentage_discount") {
        this.promotionalPrice = [];
        variations.forEach((product)=>{
          this.promotionalPrice.push(+(Number(product?.customer_price) - (Number(product?.customer_price) * (this.promotion / 100))));
        });
        
      }
      else if(this.promotionType == "discount_flat_rate"){
        this.promotionalPrice = [];
        variations.forEach((product)=>{
          this.promotionalPrice.push(+(Number(product.customer_price) - (this.promotion)));
        });
      }
    }
    else{
      this.promotion = null;
      this.promotionType = null;
      this.promotionalPrice = null;
    }
    
    if(!!this.promotionalPrice[this.firstObj] && !!this.promotion && this.promotion > 0){
      this.sendSamePriceEvent.emit(this.promotionalPrice[this.firstObj]);
    }
    else if(this.productVariations[this.firstObj]?.customer_price){
        this.sendSamePriceEvent.emit(this.productVariations[this.firstObj]?.customer_price);
    }

  }

  displayMSRPPrice(){
    for(let product of this.productVariations){
      if (Number(product.msrp_price) != 0){
        this.isMSRP = true;
        break;
      }
    }
  }

  productRedirection(sku: string, product_key: number){
    this.comm.showLoader();
    const product_name = this.commonService.setUrlNameField(this.productName).toLowerCase();
    const variation_name = this.commonService.setUrlNameField(sku);
    this.router.navigate(['/'+product_name,variation_name,'p'+product_key]);
  }

  openImageModal(content: TemplateRef<any>, image: string){
    this.firstClicked =true;
    this.openedImage = !!image && image.length > 0 ? image : '../../../../../assets/images/category_landing_sf.png'
    this.dialogRefImagePopup.open(content,{
      backdrop: true,
      backdropClass: 'ngb-image-custom-backdrop',
      fullscreen:true,
      modalDialogClass: 'ngb-image-custom-modal',
      size: 'xl',
      windowClass: 'ngb-image-custom-window',
      animation: false
    })
  }

  onClickImageModalCancel(value?: boolean){
    if (this.firstClicked == true) {
      this.firstClicked = false;
      return;
    }
    else{
      this.dialogRefImagePopup.dismissAll();
    }
  }
}
