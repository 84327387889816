import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment.prod';
import { CategoriesDataInterface, CategoryByIDPostDataInterface, CategoryFilterPostDataInterface, Common } from '../interfaces/category.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private endpoint: string = environment.apiUrl;
  private categoryBreadCrumbNames: Array<string> = [];
  private categoryBreadCrumbKeys : Array<number> = [];

  constructor(private http: HttpClient) {}

  allcategoriesList(){
    return this.http.get(this.endpoint + 'all-categories');
  }

  categoriesList() : Observable< Common<CategoriesDataInterface> > {
    return this.http.get< Common<CategoriesDataInterface> >(this.endpoint + 'categories');
  }
  singlecategory(data: CategoryByIDPostDataInterface) : Observable< Common<CategoriesDataInterface> > {
    return this.http.post< Common<CategoriesDataInterface> >(this.endpoint + 'category-by-id',data);
  }

  categoryFilter(data: CategoryFilterPostDataInterface):Observable<any>{
    return this.http.post<any>(this.endpoint+'categories-filter',data);
  }
 
  setCategoryBreadCrumb(names: Array<string>, keys: Array<number>){
    this.categoryBreadCrumbNames = names;
    this.categoryBreadCrumbKeys = keys;
  }

  getCategoryBreadCrumbNames(){
    if (this.categoryBreadCrumbNames.length > 0) {
      return this.categoryBreadCrumbNames;
    }else{
      return [];
    }
  }

  getCategoryBreadCrumbKeys(){
    if (this.categoryBreadCrumbKeys.length > 0) {
      return this.categoryBreadCrumbKeys;
    }else{
      return [];
    }
  }

}
