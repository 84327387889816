<div class="product-tabs-content mt-20"> 
    <h3>Choose Color</h3>
</div>

<div 
*ngIf="productColor.length > 0"
class="color_chip_thumb" 
style="vertical-align: middle;">
    <div 
    *ngFor="let uniqueColor of uniqueColors; let i = index"
    >
        <a 
        (click)="OnSelectProduct(uniqueColor.product_variation_key)"
        [ngClass]="{
            'color_chips color_chips_img': true ,
            'active_sku': uniqueColor.product_variation_key == selectedProductVariationKey ? true : false
            }">
            <img 
            class="preview color_chips_img" 
            title="" 
            [src]="!!uniqueColor.color_chip_img  ? domainImageBasePath+uniqueColor.color_chip_img : '../../../../../../assets/images/category_landing_sf.png'" 
            [alt]="uniqueColor.color"
            >
        </a>
        <div class="click-to-top">
            <span>{{uniqueColor.color}}</span>
            <img 
            [src]="!!uniqueColor.color_chip_img ? domainImageBasePath+ uniqueColor.color_chip_img : '../../../../../../assets/images/category_landing_sf.png'" 
            [alt]="uniqueColor.color"
            >
        </div>
    </div>
</div>