<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display:block">
                        <a name="pricinglisting"></a>
                        <div class="tab-pane fade active in mb-20" id="fp_tab1">
                            <div class="item_table_block ">
                                <div *ngFor="let warranty of warrentiesData" class="alert-danger mt-0 reviewcommt_block" style id="loggedin">
                                    <p><a href="/cdn-cgi/l/email-protection#c1a2b4b2b5aeaca4b3efb2a4b3b7a8a2a481b5b3a4aca4a2efa2aeac"><span class="__cf_email__" data-cfemail="72110701061d1f17005c011700041b1117320600171f17115c111d1f" [innerHTML]="warranty.warranty"></span></a>.<br/>
                                        <a href="https://www.dropbox.com/s/e8tpu8dczf4g459/TREMEC%20Transmission%20Warranty%20Coverage.pdf?dl=0"><strong>
                                        </strong></a><span style="caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255); font-family: Helvetica; font-size: 18px;"></span><br/>
                                        <span style="caret-color: rgb(255, 255, 255);"><br/>
                                        <br/>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div> 