import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AppConstants } from 'src/app/app.constants';
import { CommunicationService } from 'src/app/service/communication.service';
import { ProductService } from 'src/app/service/product.service';
import { ViewportScroller, NgIf } from '@angular/common';
import { NgImageSliderModule } from 'ng-image-slider';

@Component({
    selector: 'app-recently-viewed',
    templateUrl: './recently-viewed.component.html',
    styleUrls: ['./recently-viewed.component.css'],
    standalone: true,
    imports: [NgIf, NgImageSliderModule]
})
export class RecentlyViewedComponent implements OnInit, OnChanges {
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath = environment.cdnImagePath;
  loading: boolean = false;
  @Input() productKey: number = null;
  recentlyViewedProducts: any = [];
  imageObject = [];

  constructor(
    private appConstants: AppConstants,
    private comm: CommunicationService,
    private productHttp: ProductService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['productKey']?.isFirstChange() && changes['productKey']?.currentValue) {
      this.loadRecentlyViewedProducts(this.productKey);
    }
  }

  loadRecentlyViewedProducts(productKey: number): void {
    this.productHttp.getRecentlyViewedProducts(productKey).subscribe(
      {
        next:(response) =>
        {

          if (response[this.firstObj]?.success && response[this.firstObj]?.data.length > 0) {
            this.recentlyViewedProducts = response[this.firstObj]?.data;

            this.imageObject = this.recentlyViewedProducts.map((item: any) => {
              const imagePath = item.product_photo?.large_image ? `${this.cdnImagePath + item.product_photo.large_image}` : '../../../../../assets/images/category_landing_sf.png';
              return {
                image: imagePath,
                thumbImage: imagePath,
                title: item.name.substring(0,25),
                productKey: item.product_key
              };
            });
          }
          else{
            console.error('Custom Error: Unexpected Error while getting recently viewed products');
          }
        },
        error:(error:Error) =>
        {
          //later log it into a file
          // console.error('Custom Error: Unexpected Error while fetching recently viewed products from api', error);
        }
      }
    );
  }

  productRedirection(index: number): void {
    this.comm.showLoader();
    this.viewportScroller.scrollToPosition([0, 0]);
    const product_key = this.imageObject[index]?.productKey
    let name: string = this.imageObject[index]?.title;
    name = name.replaceAll(' ','-');
    localStorage.setItem('selectedproductkey',String(product_key));
    this.router.navigate(['/' + name, 'p' + product_key]);

  }

goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}



