/// <reference types="@angular/localize" />



import { enableProdMode } from '@angular/core';
import { environment, environmentAsync } from './environments/environment';
import { AppComponent } from './app/app.component';
import {  bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app.config';



  (async()=>{
    await environmentAsync;
    if (environment.production) {
      enableProdMode();
    }
    function addStylesheetAsFirstChild(url) {
      let link = document.createElement('link');
    
      link.rel = 'stylesheet';
      link.href = url;
      link.type = 'text/css';
    
      document.body.insertBefore(link, document.body.firstChild);
    }
    addStylesheetAsFirstChild(environment.cdnImagePath+environment.websitecdn);
    
    bootstrapApplication(
      AppComponent,
      appConfig
    // {
    // providers: [
    //     provideRouter(routes),
    //     importProvidersFrom(
    //       BrowserModule, 
    //       ReactiveFormsModule, 
    //       MatDialogModule, 
    //       SharedDirectiveModule, 
    //       SharedPipes, 
    //       NgxIntlTelInputModule, 
    //       NgbModule, 
    //       NgImageSliderModule, 
    //       NgbCarouselModule, 
    //       NgFor, 
    //       FormsModule
    //     ),
    //     {
    //         provide: HTTP_INTERCEPTORS,
    //         useClass: AuthInterceptor,
    //         multi: true,
    //     },
    //     provideAnimations(),
    //     provideHttpClient(withInterceptorsFromDi()),
    //   ]
    // }
  )
      .catch(err => console.error(err));
    

  })()
