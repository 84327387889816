<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display:block">
                        <a name="pricinglisting"></a>
                        <div class="tab-pane fade active in mb-20" id="fp_tab1">
                            <div class="item_table_block ">

                                <div class="tech_tips_wra">
                                    <div *ngFor="let product of techTipsData " class="row">
                                        <hr style="margin: 15px 0;border-top: 1px solid #000;">
                                        <div class="col-md-5 col-sm-12">
                                            <div class="tech_prod_img">
                                                <div class="owl-carousel content-slider-with-large-controls">
                                                    <div class="overlay-container overlay-visible">
                                                        <div class="overlay-container_outer">
                                                            <div class="overlay-container_inner">

                                                                <img [src]="(product?.tech_tips_photos?.length && product?.tech_tips_photos[firstObj]?.medium_image) ? (cdnImagePath + product?.tech_tips_photos[firstObj]?.medium_image) : '../../../../../assets/images/category_landing_sf.png'" class="img-responsive lazyload"
                                                                />
                                                            </div>
                                                        </div>
                                                        <a class="popup-img-tech182 overlay-link nopadding" [href]="cdnImagePath+product?.tech_tips_photos[firstObj]?.medium_image" [title]="product?.tech_tips_photos[firstObj]?.title">
                                                            <i class="fa fa-search-plus"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <p></p>
                                            </div>
                                        </div>

                                        <div class="col-md-7 col-sm-8">
                                            <div class="tech_prod_info">
                                                <h2 class="text-uppercase">{{product?.tech_tip_name}}</h2>
                                                <div class="body">
                                                    <div id="newpost_182" class="mb-10 f-14" style="display: none;">
                                                        <p>{{product?.tech_tip_name}}</p>
                                                    </div>
                                                    <div id="accordion">
                                                        <div id="panel182" class="panel-collapse in">
                                                            <div class="panel-body" style="padding: 0">
                                                                <p [innerHTML]="product?.tech_tip_description">
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="margin: 15px 0;border-top: 1px solid #000;">


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 