import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-customer-not-found-popup',
    templateUrl: './customer-not-found-popup.component.html',
    styleUrls: ['./customer-not-found-popup.component.css'],
    standalone: true
})
export class CustomerNotFoundPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CustomerNotFoundPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
 
  closePopup(): void {
    this.dialogRef.close();
  }
}
