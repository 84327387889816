<div class="footer">
    <div class="">
        <div class="container">
            <div class="footer-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="logo-footer">
                            <div class="teaser lazyloaded" data-expand="-40" *ngIf="footerdata?.footerLogo">
                                <a [routerLink]="['/']">
                                    <img id="logo-footer " [src]="cdnImagePath + footerdata.footerLogo[0]?.footer_logo
                                    " alt=" " class="center-block lazyloaded"
                                    loading="lazy"
                                    fetchPriority="low"
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="showFirstRowFooter" class="col-md-4 col-sm-12">
                        <div class="footer-content">
                            <ul class="list-inline mb-20 dark-links">
                                <li *ngFor="let footer of footerdata?.links?.firstRow">
                                    <a (click)="onClickLinks($event,footer.menu_link)" target="_blank">
                                        {{footer.menu_name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="showSecondRowFooter" class="col-md-4 col-sm-12">
                        <div class="footer-content">
                            <ul class="list-inline mb-20 dark-links career_links">
                                <li *ngFor="let footer of footerdata?.links?.secondRow ">
                                    <a (click)="onClickLinks($event,footer.menu_link)" target="_blank" style="cursor: pointer;">
                                        {{footer.menu_name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="footer-content">
                            <div *ngIf="showSocialIconsCopyright">
                                <div>
                                    <h2 class="title">Find Us</h2>
                                </div>
                                <div>
                                    <ul class="social-links circle animated-effect-1 margin-clear">
                                        <ng-container *ngFor="let footersocial of footerdata?.socialIconsCopyright || []">
                                            <li *ngIf="addIconClassName(footersocial.link_icon) !== 'copyright'" [class]="addIconClassName(footersocial.link_icon) === 'youtube-play' ? 'youtube' : addIconClassName(footersocial.link_icon)">
                                                <a target="_blank" [href]="footersocial.menu_link | safe: 'resourceUrl'" [attr.aria-label]="footersocial.link_icon">
                                                    <i [class]="'fa fa-' + addIconClassName(footersocial.link_icon)"></i>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="showContactDetails">
                                <div *ngIf="footerdata">
                                    <h2 class="title">
                                        <a [routerLink]="['/contact-us']">
                                            {{footerdata?.contactDetails[firstObj]?.pretty_page_name }}
                                        </a>
                                    </h2>
                                </div>
                                <div [innerHtml]="footerdata?.contactDetails[firstObj]?.company_info"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="footerwarning!=false">
                <div class=" col-lg-12 col-md-12 col-sm-12 text-center ">
                    <div class="footer-content ">
                        <div class="p65_warning ">
                            <a [attr.aria-label]="" href="https://www.p65warnings.ca.gov/ " target="_blank " class="btn btn-default text-white "> <img alt="" [src]="cdnImagePath + 'p65_warnings_icon.png'" class="img-responsive" loading="lazy" fetchPriority="low"/><span>Warning:</span> Cancer and Reproductive Harm - www.P65Warnings.ca.gov</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>