<div class="container"> 
  <div class="product_review_wra">
    <hr>
    <div class="review_prod">
      <h4 style="display:inline-block;">
        Reviews
      </h4> 
        <a 
        style="width: 152px;float:right;"  
        data-toggle="modal" 
        data-target=".testimonial-review-149" 
        class="btn btn-full btn-gray-transparent" 
        data-effect-delay="500" 
        (click)="openReviewItPopup()"
        >
            Review Product
        </a>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div 
        *ngIf="imageObject.length > 0 else noImageRef" 
        >
          <section class="section light-gray-bg clearfix p-0 sf_slider_section">
            <ng-image-slider 
            #nav 
            [images]="imageObject" 
            [infinite]="false" 
            [autoSlide]="1" 
            [imageSize]="{width: '100%', height: '220'}" 
            slideImage="1"
            >
            </ng-image-slider>
          </section>
        </div>  
        <ng-template #noImageRef
        class="review_prod_img"
        >
          <img 
          [src]="'../../../../../assets/images/category_landing_sf.png'" 
          class="lazyload "
          >
        </ng-template>
      </div>
      <div class="col-md-6 col-sm-9">
        <div 
        *ngIf="!!productName" 
        class="prod_avr_revw"
        >
          <h3 class="text-uppercase">
            {{productName}}
          </h3>
        <div class="hidden-sm-pr hidden-md-pr">
          <div>
            <span style="font-size: 14px">
              Average Rating: 0 of 5
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <span>
              Appearance
            </span>
          </div>
          <div class="col-md-9 col-sm-9">
            <div class="progress style-1">
              <span class="text"></span>
              <div 
              class="progress-bar progress-bar-danger" 
              role="progressbar" 
              aria-valuenow="40" 
              aria-valuemin="0" 
              aria-valuemax="100" 
              [style.width.%]="!!appearanceProgressBarValue ? appearanceProgressBarValue : 0"
              >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <span>
              Installation
            </span>
          </div>
          <div class="col-md-9 col-sm-9">
            <div class="progress style-1">
              <span class="text"></span>
              <div 
              class="progress-bar progress-bar-warning" 
              role="progressbar" 
              aria-valuenow="40" 
              aria-valuemin="0" 
              aria-valuemax="100" 
              [style.width.%]="!!installationProgressBarValue ? installationProgressBarValue : 0"
              >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <span>
              Price / Value
            </span>
          </div>
          <div class="col-md-9 col-sm-9">
            <div class="progress style-1">
              <span class="text"></span>
              <div 
              class="progress-bar progress-bar-info" 
              role="progressbar" 
              aria-valuenow="40" 
              aria-valuemin="0" 
              aria-valuemax="100" 
              [style.width.%]="!!priceProgressBarValue ? priceProgressBarValue : 0"
              >
                <!-- {{ priceProgressBarValue | number:'1.2-2' }} -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <span>
              Quality
            </span>
          </div>
          <div class="col-md-9 col-sm-9">
            <div class="progress style-1">
              <span class="text"></span>
              <div 
              class="progress-bar progress-bar-success" 
              role="progressbar" 
              aria-valuenow="40" 
              aria-valuemin="0" 
              aria-valuemax="100"
              [style.width.%]="!!qualityProgressBarValue ? qualityProgressBarValue : 0"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-3 mt-20 hidden-xs">
      <div>
        <span>
          Average Rating: {{ totalAverageOutOf5 | starpipe:'totalRating' }} of 5
        </span>
      </div>        
      <div class="star_inline" style="display: inline-block; margin-top: 0;">
        <ng-container 
        *ngIf="(totalAverageOutOf5 | starpipe:'fullStar') > 0;"
        >
          <ng-container *ngFor="let fullstar of (totalAverageOutOf5 | starpipe:'fullStar': true)">
            <i class="fa fa-star full-star" aria-hidden="true"></i>
          </ng-container>
        </ng-container>  
        <ng-container
        *ngIf="(totalAverageOutOf5 | starpipe:'halfStar') > 0"
        >
          <ng-container
          *ngFor="let halfstar of (totalAverageOutOf5 | starpipe:'halfStar': true )">
              <i class="fa fa-star-half-o half-star" aria-hidden="true"></i>
          </ng-container>
        </ng-container>
        <ng-container
        *ngIf="(totalAverageOutOf5 | starpipe:'noStar') > 0"
        >
          <ng-container
          *ngFor="let noStar of (totalAverageOutOf5 | starpipe:'noStar': true)">
              <i class="fa fa-star no-star" aria-hidden="true"></i>
          </ng-container>
        </ng-container>
        <!-- <ng-template #allUnselected>
            <i class="fa fa-star no-star" *ngFor="let star of [1, 2, 3, 4, 5]" aria-hidden="true"></i>
        </ng-template> -->
      </div>
    </div>
  </div>
  <hr>
    <div 
    *ngFor="let review of reviews; let i= index"
    class="comment clearfix">
      <div>
        <span>Average Rating: {{ individualTotalAverageOutOf5[i] | starpipe:'totalRating' }} of 5</span>
      </div>
      <div class="comment-avatar">
        <b>{{ review?.first_name }}</b>
      </div>
      <header class="mt-10">
        <h3>{{ review?.title }}</h3>
        <div class="comment-meta mt-10">
          <div class="star_inline" style="display: inline-block; margin-top: 0;">
            <ng-container 
            *ngIf="(individualTotalAverageOutOf5[i] | starpipe:'fullStar') > 0;"
            >
              <ng-container *ngFor="let fullstar of (individualTotalAverageOutOf5[i] | starpipe:'fullStar': true)">
                <i class="fa fa-star full-star" aria-hidden="true"></i>
              </ng-container>
            </ng-container>  
            <ng-container
            *ngIf="(individualTotalAverageOutOf5[i] | starpipe:'halfStar') > 0"
            >
              <ng-container
              *ngFor="let halfstar of (individualTotalAverageOutOf5[i] | starpipe:'halfStar': true )">
                  <i class="fa fa-star-half-o half-star" aria-hidden="true"></i>
              </ng-container>
            </ng-container>
            <ng-container
            *ngIf="(individualTotalAverageOutOf5[i] | starpipe:'noStar') > 0"
            >
              <ng-container
              *ngFor="let noStar of (individualTotalAverageOutOf5[i] | starpipe:'noStar': true)"
              >
                <i class="fa fa-star no-star" aria-hidden="true"></i>
              </ng-container>
            </ng-container>
            <!-- <ng-template #allUnselected>
                <i class="fa fa-star no-star" *ngFor="let star of [1, 2, 3, 4, 5]" aria-hidden="true"></i>
            </ng-template> -->
          </div>
        </div>
      </header>
      <div class="comment-content">
        <div class="comment-body clearfix">
          <p [innerHTML]="review?.comments"></p>
        </div>
      </div>
    </div>
  </div>
</div>
 