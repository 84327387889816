<section class="main-container min-height">
  <div class="container">
    <div class="row">
      <div class="main col-md-12">
        <fieldset>
          <div class="col-sm-12 control-label pv-50 text-center">
            <span class="thankyoucontent">
              <h2 class="text_clrp">
                <div style="text-align: center;">
                  <span style="font-size:18px;"> 
                    <span style="color:#000000;"> 
                      HELLO... You're at the right place, just the wrong page!
                      <br>
                      <br>
                      <a class="upload_button" routerLink="/" style="text-decoration:none;">
                        <span style="color:#f15c22;">Click Here</span>
                      </a> 
                      To Start At Home. 
                    </span> 
                  </span>
                </div>
              </h2>
            </span>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</section>