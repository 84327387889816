<div class="breadcrumb-container c_breadcrumb">
    <div class="container">
        <ol class="breadcrumb">
            <li class="home">
                <i class="fa fa-home" aria-hidden="true"></i>
                <u>
                    <a [routerLink]="['/']">
                        Home
                    </a>
                </u>
            </li>
            <li 
            *ngFor="let breadcrumb of breadcrumbArray"
            > 
                <u>
                    <a
                    (click)="selectBreadcrumb(breadcrumb)"
                    >
                        {{breadcrumb}}
                    </a>
                </u>
            </li>
        </ol>
    </div>
</div>       