<div class="final_product_tabs_section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="tab-content tab_section" style="display:block">
            <a name="pricinglisting"></a>
            <div class="tab-pane fade active in mb-20" id="fp_tab1">
              <div class="item_table_block ">
                <table class="item_table " style="width:100%">
                  <thead>
                    <tr>
                      <th style="width: 10%;" class="pr_sku">Sku</th>
                      <th style="width: 45%;" class="pr_descr">Description</th>
                      <th style="width: 5%;" class="hidden-xs">Qty.</th>
                    </tr>
                  </thead>
                  <tbody class="isotope-container " style="display:contents;position:relative ">
                    <ng-container *ngFor="let prod of masterProductDeatils ">
                      <tr 
                      class="sku_all isotope-item by_0 " data-filter=".by_0">
                        <td>{{ prod?.product_variations?.sku }}</td>
                        <!-- <td *ngIf="prodGroup.indexOf(prod) !==0 ">&nbsp;</td> -->
                        <td>{{ prod?.product_variations?.variation_name }}</td>
                        <td class="hidden-xs ">
                          {{prod?.quantity}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div> 
          </div>
        </div>
      </div>  
    </div>
  </div>
</div>
