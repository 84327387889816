import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpResponse } from '../interfaces/http-response.interface';
import {
  SignupPhaseOne,
  SignupPhaseTwo,
  SignupPhaseThree,
  SignupPhaseFive,
  LoginRequest,
  resendOTPRequest,
  resetPassword,
  Colab,
  createTeam,
  invitation,
  forceUpdate,
  teamwiseMemberList,
  colleagueList,
  allUsers,
  colleagueInvitation,
} from '../interfaces/signup.interface';
import 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private endpoint: string = environment.apiUrl;
  private authTokenKey:string = 'UserToken';
  username: string = null;

  constructor(private http: HttpClient, private jwt : JwtService,) {}

  getToken(): string | null {
    return localStorage.getItem(this.authTokenKey);
  }

  setToken(token: string): void {
    localStorage.setItem(this.authTokenKey, token);
  }

  signupPhaseOne(data: SignupPhaseOne) {
    //console.log('dasdad');
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/LeadSignUp',
      data
    );
  }


  signupPhaseTwo(data: SignupPhaseTwo) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/VerifyOtp',
      data
    );
  }

  resetPassword(data: resetPassword) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/forgotPassword',
      data
    );
  }

  signupPhaseThree(data: SignupPhaseThree) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/SignUp',
      data
    );
  }
  createTeam(data: createTeam) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }

  login(data: LoginRequest) {
    return this.http.post<HttpResponse>(this.endpoint + 'api/User/Login', data);
  }

  getUserDetails() {
    return this.http.get<HttpResponse>(this.endpoint + 'api/get-user');
  }

  logout() {
    return this.http.get<HttpResponse>(this.endpoint + 'logout');
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('UserToken');
  }

  resendOTP(data: resendOTPRequest) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/ResendOtp',
      data
    );
  }

  getVerticals() {
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Vertical/GetVerticals'
    );
  }
  getColabs() {
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Module/GetAllModules'
    );
  }
  saveColabs(data: Colab) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/FinalStepsAddInOrg',
      data
    );
  }
  getCentralized(id: any) {
    let data: any = {
      ID: id,
    };
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/getCentralized',
      data
    );
  }

  getAddons() {
    return this.http.get<HttpResponse>(this.endpoint + 'api/getAddons');
  }

  signupPhaseFive(data: SignupPhaseFive) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/registerFive',
      data
    );
  }
  getTeamList(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetUserAssociatedHierarchy',
      data
    );
  }
  postInvitation(data: invitation) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }

  forceUpdate(data: forceUpdate) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/AcceptInvitations',
      data
    );
  }

  teamwiseMemberList(data: teamwiseMemberList) {
    if(localStorage.getItem('org_name')!='-----' && localStorage.getItem('org_name')!=null && localStorage.getItem('invite-string')==null)
    {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllUsers',
      data
    );
    }
	return null;
  }
  getColleagueList(data: colleagueList) {
    if(localStorage.getItem('org_name')!='-----' && localStorage.getItem('org_name')!=null && localStorage.getItem('invite-string')==null)
    {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetColleaugueList',
      data
    );
    }
	return null;
  }
  /*getPunch() {
    if(localStorage.getItem('org_name')!='-----' && localStorage.getItem('org_name')!=null && localStorage.getItem('invite-string')==null)
    {
    return this.http.get<HttpResponse>(
      this.endpoint2 +'attendance/Punch/GetMemberLatestPunch'
    );
    }
	return null;
  }*/
  getAllUsers(data: allUsers) {
    if(localStorage.getItem('org_name')!='-----' && localStorage.getItem('org_name')!=null && localStorage.getItem('invite-string')==null)
    {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllUsersWithStatus',
      data
    );
    }
	return null;
  }
  postColleagueInvitation(data: colleagueInvitation) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/InviteMembers',
      data
    );
  }
  private accessTokenSubject = new BehaviorSubject<string>(null);
  public accessToken$ = this.accessTokenSubject.asObservable();

  refreshToken() {
    // let input: any = {}
      // input.token = this.jwt.getTokenFromLocalStorage();
      // input.refresh_token = this.jwt.getRefreshTokenFromLocalStorage();
     return this.http.get(this.endpoint + 'refresh-token').pipe(
      tap((data: any) => {
        this.jwt.setToken(data?.refreshed_access_token);
        this.jwt.setRefreshToken(data?.data?.refresh_token);
        this.accessTokenSubject.next(data.refreshed_access_token);
      })
    );
  }

  refreshTokenManual(){
    let input: any = {}
    input.token = this.jwt.getTokenFromLocalStorage();
    input.refresh_token = this.jwt.getRefreshTokenFromLocalStorage();
   return this.http.post(this.endpoint + 'api/User/RefreshToken',input).pipe(
    tap((data: any) => {
      this.jwt.setToken(data.data.token);
      this.jwt.setRefreshToken(data.data.refresh_token);
      this.scheduleRefresh();
    })
  );


  }
  earlyBirdUser(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetEarlybirdUser',
      data
    );
  }
  getAttendencePercentage(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetAttendancePercentage ',
      data
    );
  }

  deleteProfilePicture(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/DeleteProfilePicture',
      data
    );
  }

  updateTeamName(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }

  forgotPassword(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/SendOtpForForgotPassword',
      data
    );
  }
  forgotPassVerifyOTP(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/VerifyUserOtp',
      data
    );
  }
  changePassword(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/SendOtpForForgotPassword',
      data
    );
  }
  resetPass(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/ResetUserPassword',
      data
    );
  }
  sendLink(data){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CellularMessaging/SendMessage',
      data
    );
  }
  /*getMyAttendence(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetMyAttendance',
      data
    );
  }
  getAttendenceReport(data:any){
   let timezone= moment.tz.guess();
   const header= new HttpHeaders().set('TimeZone', timezone)
    return this.http.post<HttpResponse>(
      this.endpoint2 +'attendance/Attendance/GetMyAttendanceReport ',
      data,{headers: header}
    );
  }*/
  verifyInvCode(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/VerifyPassCode',
      data
    );
  }
  getAllActiveUsers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllActiveUsers',
      data
    );
  }
  getAllInvitedUsers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllInviteUsers',
      data
    );
  }
  cancelInvitation(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/CancelInvitations',
      data
    );
  }
  /*getTeamDays(data:any){
    let timezone= moment.tz.guess();
    const header= new HttpHeaders().set('TimeZone', timezone)
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetAttendanceSummary',
      data,{headers: header}
      );
  }*/
  loginDataRefresh(){
    let data=''
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/User/FindUserByToken'
    );
  }

  /*getAttendenceReport1(data:any){
    let timezone= moment.tz.guess();
    const header= new HttpHeaders().set('TimeZone', timezone)
     return this.http.post<HttpResponse>(
       this.endpoint2 +'attendance/Attendance/GetHierarchyWiseTimeSheetReport',
       data,{headers: header}
     );
   }
   getAttendenceReport1AllTeam(data:any){
    let timezone= moment.tz.guess();
    const header= new HttpHeaders().set('TimeZone', timezone)
     return this.http.post<HttpResponse>(
       this.endpoint2 +'attendance/Attendance/GetTimeSheetReport',
       data,{headers: header}
     );
   }*/
   getAllArchivedUsers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllInactiveUsers',
      data
    );
  }
  rwmoveActiveMembers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/RevokeUsersFromOrg',
      data
    );
  }
  reActiveMembers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/ReActivateUser',
      data
    );
  }
  changeOrgName(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/UpdateOrgDetails',
      data
    );
  }
  getTeamsAdmin(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetHierarchyList',
      data
    );
  }
  postExcel(data:any){
    // return this.http.post<HttpResponse>(
    //   this.endpoint + 'api/User/BulkInviteUsers',
    //   data
    // );
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/UploadBulkUserInvitationFile',
      data
    );

  }

  getexcel(){
    // return this.http.get<HttpResponse>(
    //   this.endpoint + 'api/Attachment/GetSampleFileForBulkInviteUser'
    // );
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Attachment/GetSampleFileForBulkInviteUser'
    );

  }


  timer: any;
  scheduleRefresh() {
  const expiryTime = this.jwt.getExpiryTime();
  const currentTime = new Date().getTime();
  let remainingTime = expiryTime - currentTime;
  if (remainingTime <= 60000) {
    this.refreshTokenManual();
  } else {
    this.timer = setTimeout(() => {
      this.scheduleRefresh();
    }, remainingTime - 60000);
  }
  }


  /*getTeamDaysInternal(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetBulkUserAttendanceSummaryDurationWise ',
      data
    );
  }
  getTeamReportInternal(data:any){
    let timezone= moment.tz.guess();
    const header= new HttpHeaders().set('TimeZone', timezone)
     return this.http.post<HttpResponse>(
       this.endpoint2 +'attendance/Attendance/GetBulkUserAttendanceSummaryDurationWiseReport',
       data,{headers: header}
     );
   }*/
   getAttributes(data:any) {
    return this.http.post<HttpResponse>(
      this.endpoint +'api/CustomAttribute/GetAllCustomAttributes',data
    );
   }
   saveAttributes(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/CustomAttribute/CreateCustomAtrributeMappingForOrg',data
    );
   }
   getDynamicAttributes(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/CustomAttribute/GetAllColumnHeaders',data
    );
   }
   getProcessStatus(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/GetProcessStatus',data
    );
   }
   getUploadProcess(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/GetUploadProcessProgress',data
    );
   }
   getOnGoingProcess(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/GetOnGoingUploadProcess',data
    );
   }

   /*getAllTeamReportDownloadStatus(data:any){

    return this.http.post<HttpResponse>(
      this.endpoint2 +'attendance/Attendance/GetReportProcessStatus',data
    );
   }

   getOnGoingReportProcess(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint2 +'attendance/Attendance/GetOnGoingUploadProcess',data
    );
   }*/

   getUserProfile(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/GetUserProfileDetails',data
    );
   }
   udpateUserProfile(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/UpdateUserProfileDetails',data
    );
   }
   getUserAssociatedTeams(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/Hierarchy/GetHierarchyListforUser',data
    );
   }

   searchUsers(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/User/SearchUser',data
    );
   }

   setWatcherOfHierarchy(data: any){
    return this.http.post(this.endpoint +'api/Hierarchy/SetWatcherOfHierarchy',data);
   }
   getWatcherListForUser(data: any){
    return this.http.post(this.endpoint +'api/Hierarchy/GetWatcherListForUser',data);
   }
   selectedUserData(data: any) {
    return this.http.post(this.endpoint + 'api/User/GetAllUsers',data);
  }

  getUserDeviceRequest(data:any){
    return this.http.post(this.endpoint + 'api/User/GetDeviceRegistrationRequestDetailsForUser',data);
  }
  approveDeviceRequest(data:any){
    return this.http.post(this.endpoint + 'api/User/ApproveDeviceRegistrationRequest',data);
  }

  deviceRequestCheckUncheck(data:any){
    return this.http.post(this.endpoint + 'api/OrgAccount/ActiveAndDeactivateDeviceRegistrationService',data);
  }

  searchTeams(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint +'api/Hierarchy/SearchHierarchy',data
    );
   }

  selectedSendbirdUserData(data:any){
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserByCommunicationDetails',data);
  }
}
