import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class SeoService {
  private endpoint: string = environment.apiUrl;

  constructor(private http: HttpClient){}

  seoparameter(data: any): Observable<any> {
    const url = `${this.endpoint}seo`;
    return this.http.post<any>(url, data);
  }
}
