import { ResolveFn } from '@angular/router';



export const newsComponentResolver: ResolveFn<number | null> = (route, state) => {
  const blogid = route.params['blogid'];
  if (route.url[0].path == 'news' && route.url[1].path == 'news'){
    localStorage.setItem('activeNewsComponent',String('news'));
    return +blogid;
  }
  else if(route.url[0].path == 'news' && route.url[1].path == 'press-releases'){
    localStorage.setItem('activeNewsComponent',String('press-releases'));
    return +blogid;
  }
  else if(route.url[0].path == 'news' && route.url[1].path == 'tech-articles'){
    localStorage.setItem('activeNewsComponent',String('tech-articles'));
    return +blogid;
  }
  else if(route.url[0].path == 'news' && route.url[1].path == 'sponsored-teams'){
    localStorage.setItem('activeNewsComponent',String('sponsored-teams'));
    return +blogid;
  }
  else{
    localStorage.setItem('activeNewsComponent',String('news'))
    return +blogid;
  }
};

export const blogComponentResolver: ResolveFn<string> = (route, state) =>{
  if (route.url[0].path == 'blog') {
    localStorage.setItem('activeNewsComponent',String('blog'))
    return 'blog'
  }
  return 'noblog';
}


