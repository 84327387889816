import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { Common } from '../interfaces/common.interface';
import { MiddlearticlesInterface } from '../interfaces/performance-section.interface';

@Injectable({
  providedIn: 'root',
})
export class WebinfoService {
  private endpoint: string = environment.apiUrl;
  private cachedData$: Observable<any>;

  constructor(private http: HttpClient){}

  infoList(): Observable<Common<MiddlearticlesInterface>> {
    const storedData = this.getStoredMiddleArticleData();
    if (storedData) {
      return of(storedData);
    }

    if (!this.cachedData$) {
      this.cachedData$ = this.http.get<Common<MiddlearticlesInterface>>(this.endpoint + 'middlearticles').pipe(
        tap(data => {
          this.storeMiddleArticleData(data);
        }),
        shareReplay(1)
      );
    }
    return this.cachedData$;
  }

  private storeMiddleArticleData(data: Common<MiddlearticlesInterface>): void {
    localStorage.setItem('middleArtical', JSON.stringify(data));
  }

  private getStoredMiddleArticleData(): Common<MiddlearticlesInterface> | null {
    const data = localStorage.getItem('middleArtical');
    return data ? JSON.parse(data) : null;
  }

  subscribe(email:string):Observable<any>{
    return this.http.post<any>(this.endpoint + 'customer-subscribe',{ email_address: email })
  }
}
