<div class="product-tabs-content section_data col-md-8 col-sm-8 mt-20" style="padding-left: 0% ;">
    <h3>Product Size</h3> 
    <ul class="nav nav-pills">
        <li 
        (click)="scrollToApplicationsTab()" 
        class="border-b"
        [ngClass]="{'border-b': 'All' == selectedSize ? true: false }"
        >
            <a 
            data-filter="*" 
            (click)="onSizeClick('All')"
            [ngClass]="{'selectedSize': 'All' == selectedSize ? true: false }"
    
            >
                All
            </a>
        </li>
        <li 
        *ngFor="let sizes of getUniqueSizes(); let i = index"
        (click)="scrollToApplicationsTab()"
        class="border-b"
        [ngClass]="{'border-b': sizes.size == selectedSize ? true: false }"
        >
            <a 
            data-filter="*" 
            (click)="onSizeClick(sizes.size)"
            [ngClass]="{'selectedSize': sizes.size == selectedSize ? true: false }"
            >
                {{sizes.size}}
            </a>
        </li>
    </ul>
</div>