import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Observable , of} from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { homeComponentSetting } from '../interfaces/homepagesettings.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemePermissionMatrixService {
  private endpoint: string = environment.apiUrl;
  private cachedData$: Observable<any>;
  
  routerOutletHomeSetting: homeComponentSetting<string> = null;
  routerOutletHomeSettingEmitter: Subject<null> = new Subject<null>;

  constructor(private http: HttpClient){}

  homepageSettings(): Observable<any> {
    const storedData = this.getStoredHomeSettngData();
    if (storedData) {
      return of(storedData);
    }

    if (!this.cachedData$) {
      this.cachedData$ = this.http.get(this.endpoint+'homepage-settings').pipe(
        tap(data => {         
          this.storeHomeSettingData(data);
        }),
        shareReplay(1)
      );
    }
    return this.cachedData$;
  }

  private storeHomeSettingData(data: any): void {
    localStorage.setItem('homeSettingData', JSON.stringify(data));
  }

  private getStoredHomeSettngData(): any | null {
    const data = localStorage.getItem('homeSettingData');
    return data ? JSON.parse(data) : null;
  }

}
