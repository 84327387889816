<div class="row">
  <div class="product-tabs-content col-md-6 col-sm-8 mt-20" style="padding-left: 0;">
    <h3>Specifications</h3>
    <table class="table data-table table-striped" border="1" style="min-width: max-content;">
      <tbody>
          <tr class="odd" *ngFor="let specification of specificationsdata">
            <th class="text-left">{{specification}}</th>
          </tr>
      </tbody>
    </table>
  </div>
</div> 