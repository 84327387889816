import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { footerSetting } from '../interfaces/homepagesettings.interface';
import { SubFooterComponent } from './sub-footer/sub-footer.component';
import { NgIf } from '@angular/common';
import { TopFooterComponent } from './top-footer/top-footer.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [TopFooterComponent, NgIf, SubFooterComponent]
})
export class FooterComponent implements OnChanges {
  showFirstRowFooter: boolean = null;
  showSecondRowFooter: boolean = null;
  showWebsiteFooter: boolean = null;
  showSocialIconsCopyright: boolean = null;
  showContactDetails: boolean = null;

  @Input() footerSettings: footerSetting<string> = null; 


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['footerSettings'].currentValue) {
      this.footerSettings = changes['footerSettings'].currentValue;

      this.showFirstRowFooter = (this.footerSettings.first_row_footer === 'true');
      this.showSecondRowFooter = (this.footerSettings.second_row_footer === 'true');
      this.showWebsiteFooter = (this.footerSettings.websiteFooter === 'true');
      this.showContactDetails = (this.footerSettings.contactDetails === 'true');
      this.showSocialIconsCopyright = (this.footerSettings.socialIconsCopyright === 'true');
    }
  }

}
