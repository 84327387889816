import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { AppConstants } from 'src/app/app.constants';

import { CategoryService } from 'src/app/service/category.service';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-category-breadcrumb',
    templateUrl: './category-breadcrumb.component.html',
    styleUrls: ['./category-breadcrumb.component.css'],
    standalone: true,
    imports: [RouterLink, NgFor, NgClass]
})
export class CategoryBreadcrumbComponent implements OnChanges, OnDestroy {
  firstObj: number = this.appConstants.firstObj
  current_category_key: number;
  search_category_key: number
  FinalArray: Array<{name: string, category_key: number}> = [];
  PromiseNextAPISearch: Promise<any>;

  make_key: number;
  model_key: number;
  year: string | number;
  @Input() name: string;
  @Input() category_key: number;
  names : Array<string> = [];
  keys: Array<number> = [];


  constructor(
    private appConstants: AppConstants,
    private categoryHttp: CategoryService,
    private router: Router,
    private commonPageService: CommanpageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.names = this.categoryHttp.getCategoryBreadCrumbNames();
    this.keys = this.categoryHttp.getCategoryBreadCrumbKeys();
      if (changes?.['name']?.currentValue) {
        if (!this.names.includes(this.name)){
          this.names.push(this.name);
          this.keys.push(this.category_key);
          this.categoryHttp.setCategoryBreadCrumb(this.names, this.keys);
        }else{
          if (this.names.includes(this.name)){
            this.names = this.names.slice(0, this.names.indexOf(this.name)+1);
            this.keys = this.keys.slice(0, this.names.indexOf(this.name)+1);

            
          }
        }
      }
  }

  onClickNavLink(index: number){
    this.names = this.names.slice(0,index+1);
    const categoryname = this.commonPageService.setUrlNameField(this.names[index]);
    const categoryKey = this.keys[index];
    this.keys = this.keys.slice(0, index+1);
    this.router.navigate(['/'+categoryname, 'c'+categoryKey]);
  }

  ngOnDestroy(): void {
    this.categoryHttp.setCategoryBreadCrumb(this.names, this.keys);
  }
}