import { Routes } from "@angular/router";
import { contactDealerComponentResolver, dealerLocatorComponentResolver, dealerLocatorGoogleMapsResolver } from "./app/resolvers/dealer-locator-component.resolver";
import { blogComponentResolver, newsComponentResolver } from "./app/resolvers/news-component.resolver";
import { AuthGuard } from "./app/service/auth.guard";
import { placeholderComponentResolver } from "./app/resolvers/palceholder-component.resolver";
import { productComponentResolver } from "./app/resolvers/product-component.resolver";

export const routes: Routes = [
  { path: '',
    children: [
      { 
        path: '', 
        loadComponent: () => 
          import('./app/templates/home/home.component').then(m => m.HomeComponent)
      },
      { 
        path: 'blog-details', 
        loadComponent: () => 
          import('./app/templates/latest-news/component/blog-details/blog-details.component').then(m => m.BlogDetailsComponent) 
      },
      { 
        path: 'become-dealer', 
        loadComponent: () =>
          import('./app/templates/become-dealer/become-dealer.component').then(m => m.BecomeDealerComponent) 
      },
      { 
        path: 'customer-login', 
        loadComponent:() => 
          import('./app/templates/customer-login/customer-login.component').then(m => m.CustomerLoginComponent)    
      },
      { 
        path: 'customer-gallery',
        loadComponent: () =>
          import('./app/templates/customer-gallery/customer-gallery.component').then(m => m.CustomerGalleryComponent), 
      },
      { 
        path: 'submit-your-ride', 
        loadComponent: () =>
          import('./app/templates/customer-gallery/component/submit-your-ride/submit-your-ride.component').then(m => m.SubmitYourRideComponent)
      },
      { 
        path: 'cart', 
        loadComponent: () =>
          import('./app/templates/cart/cart.component').then(m => m.CartComponent)
      },
      { 
        path: 'sign-up', 
        loadComponent: () =>
          import('./app/templates/sign-up/sign-up.component').then(m => m.SignUpComponent) 
      },
      { 
        path: 'forget-password', 
        loadComponent: () =>
          import('./app/templates/forget-password/forget-password.component').then(m => m.ForgetPasswordComponent) 
      },
      { 
        path: 'dealer-inquiries', 
        loadComponent: () =>
          import('./app/header/component/dealer-inquiries/dealer-inquiries.component').then(m => m.DealerInquiriesComponent) 
      },
      {
        path: 'dealer-inquiries/dealer-inquiry-thankyou' , 
        loadComponent: ()=>
          import('./app/header/component/dealer-inquiries/component/dealer-inquiry-thankyou/dealer-inquiry-thankyou.component').then(m => m.DealerInquiryThankyouComponent)
      },

      // DEALER MAP PATHS
      { 
        path: 'dealer-locator', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent) 
      },
      { 
        path: 'AL', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'AK', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'AZ', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'AR', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'CA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'CO', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'CT', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'DE', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'FL', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'GA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'HI', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'ID', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'IL', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'IN', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'KS', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'KY', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'LA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'ME', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MD', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MI', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MN', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MS', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MO', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'MT', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NE', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NV', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NH', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NJ', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NM', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NY', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'NC', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'ND', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'OH', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'OK', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'OR', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'PA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'RI', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'SC', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'SD', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'TN', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'TX', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'UT', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'VA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'WA', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'WV', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'WI', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'WY', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {state:dealerLocatorComponentResolver}
      },
      { 
        path: 'zip-city-state-search', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {nodealer: dealerLocatorComponentResolver }
      },
      { 
        path: 'international-region/92', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {regionId: dealerLocatorGoogleMapsResolver}
      },
      { 
        path: 'international-region/:id', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent),
        resolve: {regionId: dealerLocatorGoogleMapsResolver}
      },
      {
        path: 'location/:dealer', 
        loadComponent: () =>
          import('./app/header/component/dealer-locator/dealer-locator.component').then(m => m.DealerLocatorComponent), 
        resolve: {contactDealer:contactDealerComponentResolver}
      },
      {
        path: 'customer-gallery-details',
        loadComponent: () =>
          import('./app/templates/customer-gallery/component/customer-gallery-details/customer-gallery-details.component').then(m => m.CustomerGalleryDetailsComponent),
      },
      { 
        path: 'checkout', 
        loadComponent: () =>
          import('./app/templates/checkout/checkout.component').then(m => m.CheckoutComponent) 
      },
      { 
        path: 'contact-us', 
        loadComponent: () =>
          import('./app/templates/contact-us/contact-us.component').then(m => m.ContactUsComponent)
      },
      { 
        path: 'privacy-policy', 
        loadComponent: () =>
          import('./app/templates/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) 
      },
      { 
        path: 'about-us', 
        loadComponent: () =>
          import('./app/templates/about-us/about-us.component').then(m => m.AboutUsComponent) 
      },
      { 
        path: 'terms-conditions', 
        loadComponent: () =>
          import('./app/templates/terms-conditions/terms-conditions.component').then(m => m.TermsConditionsComponent) 
      },
      { 
        path: 'reset-password', 
        loadComponent: () =>
          import('./app/templates/forget-password/reset-password/reset-password.component').then(m => m.ResetPasswordComponent) 
      },
      { 
        path: 'blog', 
        loadComponent: () =>
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve: {blog: blogComponentResolver} 
      },
      { 
        path: 'careers',
        loadComponent: () =>
          import('./app/templates/careers/careers.component').then(m => m.CareersComponent), 
      },
      { 
        path: 'my-account', 
        loadComponent: () =>
          import('./app/templates/my-account/my-account.component').then(m => m.MyAccountComponent), 
        canLoad: [AuthGuard] 
      },
      { 
        path: 'my-orders', 
        loadComponent: () =>
          import('./app/templates/my-account/component/my-orders/my-orders.component').then(m => m.MyOrdersComponent)
      },
      { 
        path: 'edit-account', 
        loadComponent: () => 
          import('./app/templates/my-account/component/edit-account/edit-account.component').then(m => m.EditAccountComponent)
      },
      { 
        path: 'refer-a-friend',
        loadComponent: () =>
          import('./app/templates/my-account/component/refer-a-friend/refer-a-friend.component').then(m => m.ReferAFriendComponent) 
      },
      { 
        path: 'my-wishlist', 
        loadComponent: () =>
          import('./app/templates/my-account/component/my-wishlist/my-wishlist.component').then(m => m.MyWishlistComponent)
      },
      { 
        path: 'my-address', 
        loadComponent: () =>
          import('./app/templates/my-account/component/my-address/my-address.component').then(m => m.MyAddressComponent)
      },
      { 
        path: 'change-password', 
        loadComponent: () =>
          import('./app/templates/my-account/component/change-password/change-password.component').then(m => m.ChangePasswordComponent)
      },
      { 
        path: 'categories', 
        loadComponent: () =>
          import('./app/templates/categories/categories.component').then(m => m.CategoriesComponent)
      },
      { 
        path: 'brands', 
        loadComponent: () =>
          import('./app/templates/brands/brands.component').then(m => m.BrandsComponent)
      },
      { 
        path: 'brand-products', 
        loadComponent: () =>
          import('./app/templates/brands/component/brand-proucts/brand-proucts.component').then(m => m.BrandProuctsComponent)
      },
      { 
        path: 'video-gallery', 
        loadComponent: () =>
          import('./app/templates/video-gallery/video-gallery.component').then(m => m.VideoGalleryComponent)
      },
      { 
        path: 'order-thank-you',
        loadComponent: () =>
          import('./app/templates/order-thank-you/order-thank-you.component').then(m => m.OrderThankYouComponent) 
      },
      { 
        path: 'order-invoice', 
        loadComponent: () =>
          import('./app/templates/my-account/component/invoice-tracking/invoice-tracking.component').then(m => m.InvoiceTrackingComponent)
      },
      { 
        path: 'my-account/track-order-details',
        loadComponent: () =>
          import('./app/templates/track-order-details/track-order-details.component').then(m => m.TrackOrderDetailsComponent) 
      },
      { 
        path: 'services', 
        loadComponent: () => 
          import('./app/templates/our-services/our-services.component').then(m => m.OurServicesComponent)
      },
      { 
        path: 'customer-service', 
        loadComponent: () =>
          import('./app/templates/customer-support-service/customer-support-service.component').then(m => m.CustomerSupportServiceComponent)
      },
      { 
        path: 'career-application',
        loadComponent: () =>
          import('./app/templates/careers/component/career-application/career-application.component').then(m => m.CareerApplicationComponent)
      },
      { 
        path: 'vehicle-search', 
        loadComponent: () =>
          import('./app/templates/search-by-vehicle/component/vehicle-search/vehicle-search.component').then(m => m.VehicleSearchComponent)
      },
      { 
        path: 'payment-handler', 
        loadComponent: () =>
          import('./app/templates/payment-handler/payment-handler.component').then(m => m.PaymentHandlerComponent)
      },
      { 
        path: 'order-details', 
        loadComponent: () =>
          import('./app/templates/my-account/component/my-orders/order-details/order-details.component').then(m => m.OrderDetailsComponent)
      },
      { 
        path: 'video-gallery-details', 
        loadComponent: () =>
          import('./app/templates/video-gallery/video-gallery-details/video-gallery-details.component').then(m => m.VideoGalleryDetailsComponent)
      },
      { 
        path: 'video-gallery/:videoName/:videoKey', 
        loadComponent: () =>
          import('./app/templates/video-gallery/video-gallery-details/video-gallery-details.component').then(m => m.VideoGalleryDetailsComponent)
      },
      { 
        path: 'social-redirect', 
        loadComponent: () =>
          import('./app/templates/customer-login/social-redirect/social-redirect.component').then(m => m.SocialRedirectComponent)
      },
      { 
        path: 'logout', 
        loadComponent: () =>
          import('./app/header/header-top/component/logout/logout.component').then(m => m.LogoutComponent)
      },
      {
        path:'blog/:blogName/:blogid',
        loadComponent: () =>
          import('./app/templates/latest-news/component/blog-details/blog-details.component').then(m => m.BlogDetailsComponent)
      },
      // added this dynamic route as well to match the nav links set in footer menu
      {
        path:'news/category/:blogid',
        loadComponent: () =>
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve:{
          blogid: newsComponentResolver
        }
      },

      { path: ':name/:id',
        loadComponent: () =>
          import('./app/templates/placeholder/placeholder.component').then(m => m.PlaceholderComponent),
        resolve:{
          component: placeholderComponentResolver
        }
      },
      {
        path: 'news/news/:blogid',
        loadComponent: () => 
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve: {
          blogid: newsComponentResolver
        }
      },
      {
        path: 'news/press-releases/:blogid',
        loadComponent: () => 
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve:{
          blogid: newsComponentResolver
        }
      },
      {
        path: 'news/tech-articles/:blogid',
        loadComponent: () => 
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve:{
          blogid: newsComponentResolver
        }
      },
      {
        path: 'news/sponsored-teams/:blogid',
        loadComponent: () => 
          import('./app/templates/blog/blog.component').then(m => m.BlogComponent),
        resolve:{
          blogid: newsComponentResolver
        }
      },
      {
        path: ':name/:skuName/:id',
        loadComponent: () => 
          import('./app/templates/featured-product/component/product-details/product-details.component').then(m => m.ProductDetailsComponent),
        resolve:{
          sku: productComponentResolver,
        }
      },
      { 
        path: 'pagenotfound', 
        loadComponent: () =>
          import('./app/templates/error-page/error-page.component').then(m => m.ErrorPageComponent)      
      },
      { 
        path: '', 
        redirectTo: '/', 
        pathMatch: 'full' 
      },
      { 
        path: '**', 
        redirectTo: '/pagenotfound' 
      },
        // { path: '**', redirectTo: '/customer-login' },
    ]
   },


  ];