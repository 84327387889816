import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLazyLoadBackground]',
  standalone:true
})
export class BackgroundImageDirective implements OnInit, OnDestroy {
  @Input() appLazyLoadBackground: string | null | undefined;
  private observer: IntersectionObserver | undefined;


  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
   // Early exit if no background image URL is provided
   if (!this.appLazyLoadBackground) return;

   // Define the IntersectionObserver
   this.observer = new IntersectionObserver(
     (entries, observer) => {
       entries.forEach(entry => {
         // If the element is in the viewport
         if (entry.isIntersecting) {
           // Set the background image
           this.element.nativeElement.style.backgroundImage = `url(${this.appLazyLoadBackground})`;
           // Unobserve the element once the background is set
           observer.unobserve(entry.target);
         }
       });
     },
     {
       root: null, // Observe within the viewport
       rootMargin: '0px', // Default margin
       threshold: 0.1, // Trigger when 10% of the element is in the viewport
     }
   );

   // Start observing the element
   this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
      if (this.observer) {
        this.observer.disconnect();
      }
  }

}
