<div class="login-drp">
    <div class="ico-head">
        <h5>Acting as:</h5>
    </div>
    <div class="login-opt">
        <select id="drop" class="form-control" [(ngModel)]="selectedEmail" (change)="customerSelection($event)">
        <option value="0" selected>Retail Customer</option>
        <option *ngFor="let item of dropdownData" [value]="item.email">{{ item.name }}</option>
      </select>
    </div>
</div>
