import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-sub-category-dab',
  templateUrl: './sub-category-dab.component.html',
  styleUrls: ['./sub-category-dab.component.css'],
  standalone: true,
  imports: [RouterLink]
})
export class SubCategoryDabComponent {
  @Input() category_key: number;
  @Input() subCategories: any[] = [];
  @Input() subProducts: any[] = []
  @Input() catProductPromotion : any;
  @Input() universalProducts: any[] = [];
  @Input() name: string;
  @Input() limitSubCategories: number;
  @Input() limitSubProducts: number;
  @Input() hideProductsLoadMore: boolean;
  @Input() hideCategoriesLoadMore: boolean;
  @Output() refreshPageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subCategoriesLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subProductsLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

}
