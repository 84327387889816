<div class="col-md-12" style="overflow: hidden;clear: both;">
    <div class="c_breadcrumb bread-c_ca text-center">
        <ol class="breadcrumb" style="margin-bottom:0px; display: inline-block;">
            <li class="home">
                <i class="fa fa-home" aria-hidden="true"></i>
                <u>
                    <a [routerLink]="['/']">
                        Home
                    </a>
                </u>
            </li>
            
            <li 
            *ngFor="let name of names; let i = index"
            [ngClass]="{'active': names.length === i  ? true : false}"> 
                <u>
                    <a (click)="onClickNavLink(i)">
                        {{name}}
                    </a>
                </u>
            </li>
        </ol>
    </div>
</div> 