import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-product-breadcrumb',
    templateUrl: './product-breadcrumb.component.html',
    styleUrls: ['./product-breadcrumb.component.css'],
    standalone: true,
    imports: [RouterLink, NgFor]
})
export class ProductBreadcrumbComponent implements OnInit,OnChanges {
  @Input() productName: string = null;
  @Input() selectedSku: string = null;
  breadcrumbArray: string[] = [];

  ngOnInit(): void {
    this.setBreadCrumbArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productName']) {
      this.setBreadCrumbArray();
    }
    if (changes['selectedSku']) {
      this.setBreadCrumbArray();
    }
  }


  setBreadCrumbArray(): void{
    this.breadcrumbArray = []
    if(this.productName && this.selectedSku){
      this.breadcrumbArray.push(this.productName);
      this.breadcrumbArray.push(this.selectedSku);
    }
    else if(this.productName && !this.selectedSku){
      this.breadcrumbArray.push(this.productName);
    }
    else{
      this.breadcrumbArray = []
    }
  }

  selectBreadcrumb(breadcrumb: string): void{
    if (breadcrumb == this.productName) {
      //select All functionality if exists or show application tab and deselect color

    }
  }

}
