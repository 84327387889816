<section class="clearfix slideshow commonbg toppadding wickedtrucksMainSectionMargin bg-white skeleton-loader">
	<div class="final_product_landing_section">
		<div class="final_product_new_design">
			<div class="container top_container">
				<div class="row">
					<div class="breadcrumb-container c_breadcrumb">
						<ol class="breadcrumb"></ol>
					</div>
					<div class="main col-md-12">
						<div class="row">
							<div class="col-md-4">
								<div class="toplink"><ul role="tablist" class="nav nav-pills"><li class=""><a title="Image"><i class=""></i> </a></li><li ><a title="Video"><i class=""></i></a></li></ul></div>
								<div class="tab-content clear-style tab-carousel">
									<div class="tab-pane">
										<div class="owl-carousel content-slider-with-large-controls owl-theme">
											<div class="owl-wrapper-outer">
												<div class="owl-wrapper">
													<div class="owl-item">
													<section class="section light-gray-bg clearfix p-0 sf_slider_section">
														<div class="ng-image-slider">
															<div class="ng-image-slider-container">
																<div class="main">
																	<div class="main-inner">
																		<div class="img-div">
																			<div class="custom-image-main">
																				<img class="image" src="">
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="product-cust-wra d-none d-lg-block d-md-block d-sm-block">
									<div class="row">
										<div class="col-md-4 col-md-offset-1 col-sm-4 col-sm-offset-1">
											<img class="img-responsive center-block" src="">
										</div>
										<div class="col-md-7 col-sm-7">
										<h4></h4>
										<h3></h3>
										<h2></h2>
										<div class="row cbk-row mt-10">
											<div class="cbk">
											<img class="img-responsive" src=""></div>
											<div class="cbk-1">
											<h6></h6>
											<span></span>
											</div>
											</div>
											<div></div>
											</div>
										</div>	
								</div>
							</div>
							<div class="col-md-8 productinfo body_ico_wra">
								<div class="row">
									<div class="col-md-12">
										<div class="pull-left">
											<h1 class="product_title tabtile-font">
											</h1>
										</div>
									</div>
								</div>
								<div class="separator separators"></div>
								<div class="row">
									<div class="col-md-10">
										<div class="star">
											<span></span>
											<div class="star_inline"></div>
											<div></div>
										</div>
									</div>
									<div class="col-md-2">									
										<div class="hidden-xs cust-hide"></div>
									</div>	
									
								</div>
								<div id="color-update-price-div">
									<div class="product-price disp-in">
										<h1 class="hidden-xs total_price total_price1"></h1>
									</div>
									<div class="p65_warning scroll_table map_pricing">
										<a href="" class=""><img src="" class="img-responsive"><span></span></a>
									</div>
								</div>
								<div class="question_btn"><a href="" class="btn btn-animated"><i class=""></i></a></div>
								<div class="instruction_btn"><a href="" class="btn btn-animated"><i class=""></i></a></div>	
								<div class="product_desc">
									<span></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-md-12 final_product_tabs_header">
				<div class="tabs-wrapper">
					<nav class="tabs">
						<ul role="tablist" class="nav nav-tabs text-center">
							<li class="tab after-slide" style="margin-right: 10px; background-color: rgb(216, 226, 238);"><a></a></li>
							<li class="product_details_tab tab after-slide" style="display: block; margin-right: 5px;"><a></a></li>
							<li class="FAQS_tab tab after-slide" style="margin-right: 5px;"><a></a></li>
							<li class="tab after-slide" style="margin-right: 5px;"><a></a></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
	<div class="final_product_tabs_section">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="row">
						<div class="tab-content tab_section" style="display: block;">
							<a name="pricinglisting"></a>
							<div id="fp_tab1" class="tab-pane fade active in mb-20">
							<div class="item_table_block">
								<table class="item_table" style="width: 100%;">
									<thead>
										<tr>
											<th class="pr_sku hidden-xs sku-images" style="width: 10%;"></th>
											<th class="pr_sku" style="width: 15%;"></th>
											<th style="width: 10%;"></th>
											<th class="pr_descr" style="width: 45%;"></th>
											<th class="hidden-xs" style="width: 5%;"></th>
											<th class="hidden-xs" style="width: 10%;"></th>
											<th class="hidden-xs" style="width: 10%;"></th>
											<th class="hidden-xs" style="width: 10%;"></th>
										</tr>
									</thead>
									<tbody class="isotope-container" style="display: contents; position: relative;">
										<tr class="sku_all isotope-item by_0">
											<td class="hidden-xs sku-images">
												<div class="app-sec">
													<div class="owl-carousel content-slider-with-large-controls owl-theme" style="opacity: 1; display: block;">
														<div class="owl-wrapper-outer" style="width: 60px; left: 0px;">
															<div class="owl-wrapper" style="width: 60px; margin-left:15px; display: block;">
																<div class="owl-item">
																	<div class="overlay-container overlay-visible">
																		<div class="overlay-container_outer">
																			<div class="overlay-container_inner"><img class="img-responsive lazyload" src="" title=""></div>
																		</div>
																		<a target="_blank" class="popup-img-sku2157 overlay-link nopadding plusicon" href="" title=""><i class="fa fa-search-plus" style="left: 50px;"></i></a>
																	</div>
																</div>
															</div>
														</div>
														<div class="owl-controls clickable" style="display: none;">
															<div class="owl-pagination">
																<div class="owl-page active">
																	<span></span>
																</div>
															</div>
															<div class="owl-buttons">
																<div class="owl-prev"></div>
																<div class="owl-next"></div>
															</div>
														</div>
													</div>
												</div>
											</td>
											<td style="position: relative; z-index: 10;"><a class="sku_name"></a></td>
											<td><p class="sku_stock"></p></td>
											<td><a class="sku-desc"></a>
											</td><td class="hidden-xs">
												<p name="order_qty_24244" class="order_quantity"></p>
											</td>
											<td class="total_price hidden-xs"><s></s></td>
											<td class="total_price hidden-xs"><p class="sku_total_price"></p></td>
											<td class="hidden-xs">
												<div class="cust-addcart">
													<button class="btn btn-cart add_to_cart_checkout cart-btn" id="2980"></button>
												</div>
											</td>
											</tr>
											<tr class="sku_all isotope-item by_0">
												<td class="hidden-xs sku-images">
													<div class="app-sec">
														<div class="owl-carousel content-slider-with-large-controls owl-theme" style="opacity: 1; display: block;">
															<div class="owl-wrapper-outer" style="width: 60px; left: 0px;">
																<div class="owl-wrapper" style="width: 60px; margin-left:15px; display: block;">
																	<div class="owl-item">
																		<div class="overlay-container overlay-visible">
																			<div class="overlay-container_outer">
																				<div class="overlay-container_inner">
																					<img class="img-responsive lazyload" src="" title="">
																				</div>
																			</div>
																			<a target="_blank" class="popup-img-sku2157 overlay-link nopadding plusicon" href="" title=""><i class="fa fa-search-plus" style="left: 50px;"></i></a>
																		</div>
																	</div>
																</div>
															</div>
															<div class="owl-controls clickable" style="display: none;">
																<div class="owl-pagination">
																	<div class="owl-page active">
																		<span></span>
																	</div>
																</div>
																<div class="owl-buttons">
																	<div class="owl-prev"></div>
																	<div class="owl-next"></div>
																</div>
															</div>
														</div>
													</div>
												</td>
												<td style="position: relative; z-index: 10;"><a class="sku_name"></a></td>
												<td><p class="sku_stock"></p></td>
												<td><a class="sku-desc"></a></td>
												<td class="hidden-xs">
													<p name="order_qty_24244" class="order_quantity "></p>
												</td>
												<td class="total_price hidden-xs"><s></s></td>
												<td class="total_price hidden-xs"><p class="sku_total_price"></p></td>
												<td class="hidden-xs">
													<div class="cust-addcart">
														<button class="btn btn-cart add_to_cart_checkout cart-btn" id="111"> </button>
													</div>
												</td>
											</tr>
											<tr class="sku_all isotope-item by_0">
												<td class="hidden-xs sku-images">
													<div class="app-sec">
														<div class="owl-carousel content-slider-with-large-controls owl-theme" style="opacity: 1; display: block;">
															<div class="owl-wrapper-outer" style="width: 60px; left: 0px;">
																<div class="owl-wrapper" style="width: 60px; margin-left:15px; display: block;">
																	<div class="owl-item">
																		<div class="overlay-container overlay-visible">
																			<div class="overlay-container_outer">
																				<div class="overlay-container_inner">
																					<img class="img-responsive lazyload" src="" title="">
																				</div>
																			</div>
																			<a target="_blank" class="popup-img-sku2157 overlay-link nopadding plusicon" href="" title=""><i class="fa fa-search-plus" style="left: 50px;"></i></a>
																		</div>
																	</div>
																</div>
															</div>
														<div class="owl-controls clickable" style="display: none;">
															<div class="owl-pagination">
																<div class="owl-page active">
																	<span></span>
																</div>
															</div>
															<div class="owl-buttons">
																<div class="owl-prev"></div>
																<div class="owl-next"></div>
															</div>
															</div>
														</div>
													</div>
												</td>
												<td style="position: relative; z-index: 10;"><a class="sku_name"></a></td>
												<td><p class="sku_stock"></p></td>
												<td><a class="sku-desc"></a></td>
												<td class="hidden-xs">
													<p name="order_qty_24244" class="order_quantity"></p>
												</td>
												<td class="total_price hidden-xs"><s></s></td>
												<td class="total_price hidden-xs"><p class="sku_total_price"></p></td>
												<td class="hidden-xs">
													<div class="cust-addcart">
														<button class="btn btn-cart add_to_cart_checkout cart-btn" id="118"></button>
													</div>
												</td>
											</tr>
											<tr>
												<td class="hidden-xs"></td>
												<td class="hidden-xs"></td>
												<td class="hidden-xs"></td>
												<td class="hidden-xs"></td>
												<td class="hidden-xs"></td>
												<td class="hidden-xs">
													<div class="cust-addcart">
														<button class="btn btn-cart add_to_cart_checkout load-btn"> </button>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
