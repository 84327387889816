<div  style="height: 268.9px; width: 478px; overflow-y: hidden; padding: 17px;" >
  <div class="sa-icon-custom sa-warning-custom pulseWarning-custom" style="display: block; margin: 20px auto;">
    <span class="sa-body-custom pulseWarningIns-custom"></span>
    <span class="sa-dot-custom pulseWarningIns-custom"></span>
  </div>
  <h2></h2>
  <p style="display: block;" style="font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif  !important; text-align: center;">
    You may not add to cart until all the options for this product have been selected.
  </p>
  <div class="sa-button-container-custom text-center">
    <button class="confirm sa-button-custom text-center" tabindex="1"
      style="
      display: inline-block; 
      background-color: #dc0109!important; 
      box-shadow: rgba(241, 92, 34, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset;
      "
      (click)="closeDialog()"
      >
        OK
      </button>
  </div>
</div>