<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display:block">
                        <a name="pricinglisting"></a>
                        <div class="tab-pane fade active in mb-20" id="fp_tab1">
                            <div class="item_table_block ">
                                <div *ngIf="productsdetailinfo.length > 0" class="featured_gallery_block">
                                    <div innerHTML=" {{productsdetailinfo}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
 