import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'starpipe',
    standalone: true
})
export class StarArrayPipe implements PipeTransform {
  
  transform(totalAverageOutOf5: number, argument: string, isArray: boolean = false,totalStar: number = 5): any {
    const fullStar = Math.floor(totalAverageOutOf5);
    const halfStar = (totalAverageOutOf5 - fullStar) > 0 ? 1 : 0;
    const noStar = totalStar - halfStar - fullStar;
    const fullStarArray = []
    const halfStarArray= [];
    const noStarArray = [];

    if (argument == 'totalRating') {
      const totalRating = fullStar+ (0.5* halfStar);
      return totalRating;
    }

    if (argument == 'fullStar') {
      if (isArray) {
        for(let i= 1; i < fullStar + 1; i++){
          fullStarArray.push(i);
        }
        return fullStarArray
      }
      else{
        return fullStar
      }
    }
    else if(argument == 'halfStar'){
      if (isArray) {
        for(let i = 1; i < halfStar + 1; i++){
          halfStarArray.push(i)
        }
        return halfStarArray;
      }
      else{
        return halfStar;
      }
    }
    else if (argument == 'noStar') {
      if (isArray) {
        for(let i = 1; i < noStar +1; i++){
          noStarArray.push(i);
        }
        return noStarArray
      }
      else{
        return noStar
      }
    }
    return 0;
  }

}
