import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-subscribe-popup',
    templateUrl: './subscribe-popup.component.html',
    styleUrls: ['./subscribe-popup.component.css'],
    standalone: true
})
export class SubscribePopupComponent {
 constructor(
    public dialogRef: MatDialogRef<SubscribePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
 
  closePopup(): void {
    this.dialogRef.close();
  }

}
