import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-product-detail-info',
    templateUrl: './product-detail-info.component.html',
    styleUrls: ['./product-detail-info.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class ProductDetailInfoComponent {

  @Input() productsdetailinfo: string = '';

  constructor(){}

  }
