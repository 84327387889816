import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { environment } from 'src/environments/environment.prod';
import { NgFor } from '@angular/common';
@Component({
    selector: 'app-tech-tips',
    templateUrl: './tech-tips.component.html',
    styleUrls: ['./tech-tips.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor]
})
export class TechTipsComponent implements OnInit {
  firstObj: number = this.appConstants.firstObj;
  product_key: number = null;
  cdnImagePath: string = environment.cdnImagePath
  @Input() techTipsData: any;

  constructor(
    private appConstants: AppConstants
  ){}
    
  ngOnInit(): void {
    this.product_key = +localStorage.getItem('selectedproductkey') || null;
  }
  
}
