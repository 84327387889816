import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/app.constants';
import { DecimalPipe } from '../../../../../pipes/decimal.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-product-option',
    templateUrl: './product-option.component.html',
    styleUrls: ['./product-option.component.css'],
    standalone: true,
    imports: [NgFor, NgIf, ReactiveFormsModule, NgxIntlTelInputModule, FormsModule, TitleCasePipe, DecimalPipe]
})
export class ProductOptionComponent implements OnChanges {
  firstObj: number = this.appConstants.firstObj;
  
  product_key: number;

  isOptionGroup: boolean = true;
  selectedOptionsSkuPrices: number[];
  selectedOptionsSkus: string[];
  selectedOptionsProductVariationKeys: Array<number>;
  selectedOptionsQtys: number[];
  selectedOptionKeys: Array<string> = [];
  numberOfGroups:number;
  groups: {[option_group_key: number]: number| ''} = {};
  groupsDetails: {[option_group_key:number] : any} = {}

  
  productOptionsDisplay: any[] = [];
  requiredGroup: {[product_option_group_key: number]: number | ''} = {};

  @Input() selectedProductVariationKey: number = null
  @Input() productOptions: any[] = [];
  @Input() isColorSelected: boolean = null;
  @Input() isSizeSelected: boolean = null;
  
  
  @Output() sendOptionsDataEvent: EventEmitter<{
    skus: Array<string>,
    qtys: Array<number>,
    prices: Array<number>,
    variationKeys: Array<number>,
    OptionKeys: Array<string>,
    requiredGroup: {[product_option_group_key: number]: number | ''

    }
  }> = new EventEmitter<{
    skus: Array<string>,
    qtys: Array<number>,
    prices: Array<number>,
    variationKeys: Array<number>,
    OptionKeys: Array<string>,
    requiredGroup: {[product_option_group_key: number]: number | ''}
  }>();

  @Output() productReplacementEvent: EventEmitter<{variationKey: number,productKey: number }> = new EventEmitter<{variationKey: number, productKey: number}>();


  constructor(
    private appConstants: AppConstants,
    public dialog: MatDialog
  ) {}

  

  ngOnChanges(changes: SimpleChanges): void {
  (async ()=>{
    /*
    - if selectedProductVariationKey is same as assigned sku in option then remove those option 
    - set default_selection option  & send calculations to parent component
    */
     
    if(changes['selectedProductVariationKey']?.currentValue){
      if (this.productOptions.length > 0) {
        this.renderProductOptions();
        this.setSelectedOptions();
        this.sendData();
      }             
    }
  })();
  }

  sendData(){
    this.sendOptionsDataEvent.emit({
      skus: this.selectedOptionsSkus,
      prices: this.selectedOptionsSkuPrices,
      qtys: this.selectedOptionsQtys,
      variationKeys: this.selectedOptionsProductVariationKeys,
      requiredGroup: this.requiredGroup,
      OptionKeys: this.selectedOptionKeys
    })
  }

  setSelectedOptions(){
    this.selectedOptionsSkus = []
    this.selectedOptionsSkuPrices = [];
    this.selectedOptionsQtys = [];
    this.selectedOptionsProductVariationKeys = []
    this.selectedOptionKeys = [];
    const groupKeys = Object.keys(this.groups);
    if (groupKeys.length > 0){
      for(let groupKey of groupKeys){
        const group = this.groupsDetails[groupKey];
        //sku
        let sku: string = group?.['sku'] || '';
        this.selectedOptionsSkus.push(sku);
        //price
        let price: number = 0
        if (group?.['price_adjustment']) {
          if (group?.['price_adjustment'] == 'add') {
            price = +group?.['price'];
          }
          else if(group?.['price_adjustment'] == 'subtract'){
            price = -Number(group?.['price']);
          }
          else if(group?.['price_adjustment'] == 'none'){
            price = 0;
          }
        }
        else{
          price = 0;
        }
        this.selectedOptionsSkuPrices.push(price);
        //qty
        let qty: number = +group?.['add_to_cart_qty'] || 0;
        this.selectedOptionsQtys.push(qty);
        //variationKey
        let variationKey = group?.['assigned_sku'] || 0;
        this.selectedOptionsProductVariationKeys.push(variationKey)
        //optionkeys
        let optionKey = group['product_option_key'] || '';
        this.selectedOptionKeys.push(String(optionKey));
      }
    }
  }


  renderProductOptions(){
    //set productOptionsDisplay, groups, required Groups
    this.productOptionsDisplay = [];
    this.requiredGroup = {};

    for(let i= 0; i < this.productOptions.length; i++){
      let productOptionsGroup = this.productOptions[i];
      if (productOptionsGroup['is_required'] == 1){
        this.requiredGroup[productOptionsGroup.product_option_group_key] = '';
      }
      this.groups[productOptionsGroup.product_option_group_key] = '';
      this.groupsDetails[productOptionsGroup?.product_option_group_key] = {}
      this.productOptionsDisplay.push({
        product_option_group_key: productOptionsGroup.product_option_group_key,
        product_key: productOptionsGroup.product_key,
        option_group_name: productOptionsGroup.option_group_name,
        is_required: productOptionsGroup.is_required,
        product_option: []
      });
      for(let option of productOptionsGroup.product_option){
        if(option['assigned_sku'] != this.selectedProductVariationKey){
          this.productOptionsDisplay[i]?.['product_option'].push(option);
          if(productOptionsGroup.is_required){
            if (option['default_selection'] == 1) {
              this.requiredGroup[productOptionsGroup.product_option_group_key] == +option['product_option_key']
              this.groupsDetails[productOptionsGroup.product_option_group_key] = option
            }
          }

        }
        //if after updating for current variationKey if particular group has no option left then it will not show the group
        this.productOptionsDisplay[i]?.['product_option'].length == 0 ? this.productOptionsDisplay.splice(i,1) : null; 
        // delete this.requiredGroup[productOptionsGroup.product_option_group_key];
      }
    }   
  }

  onOptionChange(productOptionKey: number| '', groupKey: number, groupindex: number): void{
    const requiredGroupKeys = Object.keys(this.requiredGroup);
    this.productOptionsDisplay.map((group)=>{
      group?.['product_option'].map((option) =>{
        if (productOptionKey == +option?.['product_option_key']) {
          this.groupsDetails[groupKey] = option;
          requiredGroupKeys.length > 0 && requiredGroupKeys.includes(String(groupKey)) ? this.requiredGroup[groupKey] = option?.['product_option_key'] : null;
        }
      });
    });
    const option = this.groupsDetails[groupKey];
    if (productOptionKey == '') {
      this.groupsDetails[groupKey] = {};
      requiredGroupKeys.length > 0 && requiredGroupKeys.includes(String(groupKey)) ? this.requiredGroup[groupKey] = '' : null;
      this.selectedOptionsSkus[groupindex] = '';
      this.selectedOptionsSkuPrices[groupindex] = 0;
      this.selectedOptionsQtys[groupindex] = 0;
      this.selectedOptionsProductVariationKeys[groupindex] = 0;
      this.selectedOptionKeys[groupindex] = ''
      this.sendData();
      return;
    }
    
    let type = option?.['option_type']; 
    if (type == 'replace_product_sku'){ 
      this.sendProductReplacementData(option.assigned_sku,option?.product_key);
      return;
    }
    else if(type == 'enter_option_name'){
      this.selectedOptionsSkus[groupindex] = '';
      if (option?.['price_adjustment'] == 'add'){
        this.selectedOptionsSkuPrices[groupindex] = +option?.['price'];
      }
      else if(option?.['price_adjustment'] == 'subtract'){
        this.selectedOptionsSkuPrices[groupindex] = -Number(option?.['price']);
      }
      else if(option?.['price_adjustment'] == 'none'){
        this.selectedOptionsSkuPrices[groupindex] = 0;
      }
      this.selectedOptionsQtys[groupindex] = option?.['add_to_cart_qty'];
      this.selectedOptionsProductVariationKeys[groupindex] = option?.['assigned_sku'];
      this.selectedOptionKeys[groupindex] = String(option?.['product_option_key']) || '';
      this.sendData();
      return;
    }
    else if(type == 'select_product_sku'){
      this.selectedOptionsSkus[groupindex] = option?.['sku'];
      this.selectedOptionsSkuPrices[groupindex] = +option?.['price'];
      this.selectedOptionsQtys[groupindex] = option?.['add_to_cart_qty'];
      this.selectedOptionsProductVariationKeys[groupindex] = option?.['assigned_sku'];
      this.selectedOptionKeys[groupindex] = String(option?.['product_option_key']) || '';
      this.sendData();
    return;
    }
  }

  sendProductReplacementData(variationKey: number, productKey: number){
    this.productReplacementEvent.emit({
      variationKey: variationKey,
      productKey: productKey
    })

  }
}