<div class="container-fluid" *ngIf="galleryPhotos?.length > 0">
  <div class="prod_gallery">
    <div class="row">
      <div class="col-md-12 text-center">
        <h5>Gallery</h5>
      </div>
    </div>

    <div class="tab-content">
      <div class="tab-pane fade tab-cont-recently active in" id="rv_tab1">
        <section class="section light-gray-bg clearfix p-0 sf_slider_section hand-pointer">
          <div class="d-none d-sm-block hand-pointer ">
            <ng-image-slider #nav [images]="imageObject " [infinite]="false" [autoSlide]="1 "
              [imageSize]="{width: '382px', height: '234px'} " slideImage="1 ">
            </ng-image-slider>
          </div>
          <div class="d-block d-sm-none ">
            <ng-image-slider #nav [images]="imageObject "  [infinite]="false" [autoSlide]="1 "
              [imageSize]="{width: '382px', height: '234px'} " slideImage="1 ">
            </ng-image-slider>
          </div>
        </section>
      </div>
    </div>

    <!-- Fallback template for no images -->
    <ng-template #noImageRef class="review_prod_img">
      <img [src]="'../../../../../assets/images/category_landing_sf.png'" class="lazyload">
    </ng-template>
  </div>
</div>
