import { ResolveFn } from '@angular/router';

export const dealerLocatorComponentResolver: ResolveFn<string | boolean> = (route) => {
  const state_id = route.url[0];
  if (state_id.path == 'zip-city-state-search') {
    return true;
  }
  return state_id.path;
};



export const dealerLocatorGoogleMapsResolver: ResolveFn<number> = (route) => {
  const regionId = +route.url[1].path;
  return regionId;
};

export const contactDealerComponentResolver: ResolveFn<boolean> = (route) => {
  return true;
}