import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/service/product.service';
import { environment } from 'src/environments/environment.prod';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-gallary-photos',
    templateUrl: './gallary-photos.component.html',
    styleUrls: ['./gallary-photos.component.css'],
    standalone: true,
    imports: [NgIf, NgImageSliderModule],
})
export class GallaryPhotosComponent implements OnInit {
  galleryPhotos: any;
  imageObject: Array<object> = [];
  productKey: any;
  cdnImagePath: string = environment.domainImageBasePath;

  constructor(private prodservice: ProductService) {}

  ngOnInit(): void {
    this.productKey = +localStorage.getItem('selectedproductkey') || null;
    this.fetchGalleryPhotos(this.productKey);
  }

  fetchGalleryPhotos(productKey: number): void {
    this.prodservice.galaryphotos(productKey).subscribe((response: any) => {
      if (response?.[0]?.success === true && response[0]?.data?.length > 0) {
        this.galleryPhotos = response[0].data[0].gallery_photos;
        this.populateImageObject(this.galleryPhotos);
      } else {
        this.galleryPhotos = [];
      }
    });
  }

  populateImageObject(galleryPhotos: any): void {
    this.imageObject = galleryPhotos.map((item: any) => {
      const imagePath = item.large_image
        ? `${this.cdnImagePath + item.large_image}`
        : '../../../../../assets/images/category_landing_sf.png';
      return {
        image: imagePath,
        thumbImage: imagePath,
      };
    });
  }
}
