import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimal',
    standalone: true
})
export class DecimalPipe implements PipeTransform {

  transform(value: number| string, ...args: unknown[]): unknown {
    return Number(value).toFixed(2);
  }

}
