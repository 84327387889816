
<div class="sweet-overlay" tabindex="-1" style="opacity: 1; display: block;"></div>
<div id="revmodal" class="modal fade testimonial-review-146 fruzzi_filter_popup in" tabindex="-1" role="dialog"  style="display: block; margin-top: -166px;"
  aria-labelledby="mySmallModalLabel" aria-hidden="false" style="display: block;" >
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="padding: 20px;">
      <div class="modal-header1" >
        <div style="overflow: hidden;clear: both">
          <button type="button" class="close" style="float: right" data-dismiss="modal" (click)="closePopup()">×</button>
        </div>
      </div>

      <form role="form" method="post" action="https://www.mcleodracing.com/product/product_review" class=""
        id="review_submit" novalidate="novalidate">
		<div class="sa-icon sa-success animate" style="display: block;">
          <span class="sa-line sa-tip animateSuccessTip"></span>
          <span class="sa-line sa-long animateSuccessLong"></span>
          <div class="sa-placeholder"></div>
          <div class="sa-fix"></div>
        </div>
        <h2 class="formateH2">Hi!</h2>
        <p style="display: block;">Your item has been added to your wish list.</p>

        <div class="sa-button-container" style="text-align: center;">
          <button class="cancel" tabindex="2" style="display: none;">Cancel</button>
          <button class="confirm" tabindex="1" 
          class="btn btn-lg btn-gray-transparent" style="background-color: rgb(198, 32, 38);"
            (click)="closePopup()">OK</button>
        </div>
      </form>
    </div>
  </div>