import { Component } from '@angular/core';

@Component({
    selector: 'app-category-skeleton',
    templateUrl: './category-skeleton.component.html',
    styleUrls: ['./category-skeleton.component.css'],
    standalone: true
})
export class CategorySkeletonComponent {

}
