import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { CartService } from 'src/app/service/cart.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { HeaderService } from 'src/app/service/header.service';
import { JwtService } from 'src/app/service/jwt.service';

import { CustomerNotFoundPopupComponent } from './customer-not-found-popup/customer-not-found-popup.component';
import { NgFor } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-associate-customer-list',
    templateUrl: './associate-customer-list.component.html',
    styleUrls: ['./associate-customer-list.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, NgxIntlTelInputModule, FormsModule, NgFor]
})
export class AssociateCustomerListComponent implements OnInit, OnDestroy {
  firstObj: number = this.appConstants.firstObj;
  dropdownData: { value: string; name: string; email: string }[] = [];
  selectedEmail: string = '0';
  showCorporateDropdown = false;
  country_code = 'US';
  order_key: number = null;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private comm: CommunicationService,
    private appConstants: AppConstants,
    private header: HeaderService,
    private cartHttp: CartService,
    private jwt: JwtService,
    public dialog: MatDialog,
    private router: Router
  ) {
   }

    ngOnInit(): void {
    const storedDropdownData = localStorage.getItem('dropdownData');
    const storedSelectedEmail = localStorage.getItem('selectedEmail');

    if (storedDropdownData) {
      this.dropdownData = JSON.parse(storedDropdownData);
    } else {
      this.getDropdownList();
    }
    if (storedSelectedEmail) {
      this.selectedEmail = storedSelectedEmail;
    } else {
      this.selectedEmail = '0';
    }
    if (this.jwt.mypage) {
    }
  }


  getDropdownList() {
    this.header.getDropdownData().subscribe({
      next: (response) => {
        const formattedDropdownData = Object.entries(response[this.firstObj]?.data).map(([key, item]: [string, any]) => ({
          value: key,
          name: item.dealer_name,
          email: item.email_address,
        }));
        this.comm.setDropdownData(formattedDropdownData);
        this.dropdownData = formattedDropdownData;
        localStorage.setItem('dropdownData', JSON.stringify(formattedDropdownData));
      },
      error: (error) => {
        console.error('Error fetching dropdown data:', error);
      }
    });
  }

  customerSelection(event: Event) {
  const target = event.target as HTMLSelectElement;
  const selectedValue = target.value;
  this.selectedEmail = selectedValue === '0' ? localStorage.getItem('email') || '0' : selectedValue;
  localStorage.setItem('selectedEmail', this.selectedEmail);

  const userData = {
    email_address: this.selectedEmail,
    type: 'corporate',
  };

  this.comm.dealerChangeEffect();

  this.cartHttp.postCorporateCustomer(userData).subscribe(
    (response) => {
      if (response.success === true) {
        const dealerToken = localStorage.getItem('UserToken');
        localStorage.setItem('dealerToken', dealerToken);
        localStorage.setItem('UserToken', response.token);

        if (selectedValue === '0') {
          localStorage.removeItem('dealerToken');
          (event.target as HTMLSelectElement).value = '0';
          this.selectedEmail = '0';
          localStorage.setItem('selectedEmail', this.selectedEmail);
        }
         this.getCartVerify();
        if (this.router.url !== '/') {
          const route = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([route]);
          });
        }
      } else {
        this.openPopup();
        (event.target as HTMLSelectElement).value = '0';
      }
    },
    (error) => {
      console.error('API error:', error);
    }
  );
}

  getCartVerify() {
  const verifydata = {
    order_key: +localStorage.getItem('orderId'),
    country_code: this.country_code
  };

  if (verifydata.order_key) {
    this.cartHttp.getCartVerify(verifydata).subscribe({
      next: (response) => {
      },
      error: (error) => {
        console.error('Error verifying cart:', error);
      }
    });
  } else {
    console.error('No order ID found in local storage');
  }
}


openPopup(): void {
    this.dialog.open(CustomerNotFoundPopupComponent, {
      width: '300px',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
