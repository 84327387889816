import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment.prod';

import { Subscription  } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';
import { PaginationService } from 'src/app/service/pagination.service';
import { FooterService } from 'src/app/service/footer.service';

import { Common } from 'src/app/interfaces/common.interface';
import { footers } from 'src/app/interfaces/footer.interface';

import { SearchByVehicleComponent } from 'src/app/templates/search-by-vehicle/search-by-vehicle.component';
import { SafePipe } from '../../pipes/youtubesafeurl-passing.pipe';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-top-footer',
    templateUrl: './top-footer.component.html',
    styleUrls: ['./top-footer.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, NgFor, SafePipe]
})

export class TopFooterComponent implements OnInit, OnDestroy {
  firstObj: number = this.appConstants.firstObj;
  storeImagePath: string = environment.storeImagePath;
  cdnImagePath :string =  environment.cdnImagePath;
  footerdata: footers;
  currentRoute: string;
  currentRouteSub: Subscription;
  footerwarning: boolean = false;

  @Input() showFirstRowFooter: boolean = null;
  @Input() showSecondRowFooter: boolean = null;
  @Input() showSocialIconsCopyright: boolean = null;
  @Input() showContactDetails: boolean = null;

  constructor(
    private appConstants: AppConstants,
    private paginationService: PaginationService,
    private footerHttp: FooterService,
    private router: Router,
    private renderer: Renderer2,
    private dialogref: MatDialog
    ){}

  ngOnInit(): void {
    this.currentRouteSub =  this.router.events.subscribe(
      {
        next:(event: any) =>
        {
          if (event instanceof NavigationEnd) {
            // Update currentRoute when navigation ends
            this.currentRoute = this.router.url.slice(1);
          }
        },
        error:(error: Error) =>
        {
          console.error("Unexpected Error happened while setting the currentRoute URL" , error);
        },
      }
    );

    this.getFooterDataFromLocalstorage();
    this.getFooterDataAndStoreInLocalStorage();
  }

  getFooterDataAndStoreInLocalStorage(): void {
    this.footerHttp.footerList().subscribe(
      {
        next:(response: Common<footers>) =>
        {
          this.footerdata = response[this.firstObj].data;
          this.footerwarning = response[this.firstObj].data.prop_65_warning_setting[0]?.prop_65_warning_setting == 'true';
          localStorage.setItem('footerData', JSON.stringify(this.footerdata));
        },
        error:(error: Error) =>
        {
          console.error("Custom Eror: Unexpected Error while fetching data from footers", error);
        },
      }
    );
  }

  getFooterDataFromLocalstorage(): void {
    const getFooterData = localStorage.getItem('footerData');
    if (getFooterData) {
      try {
        this.footerdata = JSON.parse(getFooterData);
      } catch (error) {
        console.error('Error parsing Footer data from localStorage', error);
      }
    } 
    else {
      console.warn('No header data found in localStorage');
    }
  }

  onClickLinks(event: Event, navLink: string): void{
    if(navLink.startsWith('#vehicleSearch')){
      this.dialogref.open(SearchByVehicleComponent,{

      })
      return;
    }
    if (navLink.startsWith("https")) {
      this.renderer.setAttribute(event.target,'href',navLink);
    }
    else if(navLink.startsWith(this.currentRoute,1) && this.currentRoute.length > 0){
      this.paginationService.scrollToTop('smooth','bodyscroll');
    }else{
      this.router.navigate([navLink]);

    }
  }

  addIconClassName(iconName: string): string{
    switch (iconName) {
      case 'Facebook':
        return 'facebook';

      case 'Twitter':
        return 'twitter';

      case 'Instagram':
        return 'instagram';

      case 'Youtube':
        return 'youtube-play'

      default:
        return 'copyright';
    }
  }

  ngOnDestroy(): void {
    if (this.currentRouteSub) {
      this.currentRouteSub.unsubscribe();
    }
  }
}
