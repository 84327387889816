import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, Subject } from 'rxjs';
import { LoginRequestPostDataInterface } from '../interfaces/request-interfaces/signup.interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private endpoint: string = environment.apiUrl;
  private isLoggedIn: boolean;

  private isLoggedIn$:  Subject<boolean> = new Subject<boolean>();
  private isLoggedInObservable: Observable<boolean> = this.isLoggedIn$.asObservable();

  constructor(private http: HttpClient) {    
    this.isLoggedInObservable.subscribe({
      next:(value: boolean) =>{
        this.isLoggedIn = value;
      },
    });
  }

  login(data: LoginRequestPostDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'login', data);
  }

  getUserDetails(token:any) {
    return this.http.get(this.endpoint + 'get-user', token);
  }

  SocialLogin(userData: any): Observable<any> {
    return this.http.post(this.endpoint + 'auth/social', userData);
    
  }
  /*googleSignIn(token: string): Observable<any> {
    // Send the Google ID token to your backend API for authentication
    return this.http.get<any>(`http://127.0.0.1:8000/api/v1/auth/google?token=${token}`);
  }*/

    loadUserCartproduct(token: string): Observable<any> {
    return this.http.post<any>(this.endpoint + 'cart', token);
  }

  setUserLoggedIn(isLoggedIn: boolean){
    
    this.isLoggedIn$.next(isLoggedIn);
    
  }
  isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }



}
