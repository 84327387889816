import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProductService } from 'src/app/service/product.service';
import { MatDialog } from '@angular/material/dialog';
import { ReviewItPopupComponent } from 'src/app/templates/featured-product/component/product-details/review-it-popup/review-it-popup.component';
import { environment } from 'src/environments/environment.prod';
import { AppConstants } from 'src/app/app.constants';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { StarArrayPipe } from '../../../../pipes/star-array.pipe';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-product-review',
    templateUrl: './product-review.component.html',
    styleUrls: ['./product-review.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgImageSliderModule,
        NgFor,
        StarArrayPipe,
    ],
})
export class ProductReviewComponent implements OnChanges{
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath: string= environment.cdnImagePath;
  productsreviewdata: any;
  product_photos:any;
  
  // reviewsdata: any[] = [];
  priceProgressBarValue: number | null = null;
  
  appearances:number[] = [];
  appearancesSum: number = null;
  appearanceProgressBarValue: number | null = null;
  
  installations:number[] = [];
  installationsSum: number = null;
  installationProgressBarValue: number | null = null;
  
  prices:number[] = [];
  pricesSum: number = null;
  
  qualitys: number[] = [];
  qualitysSum: number = null;
  qualityProgressBarValue: number | null = null;
  
  totalSum:number = null;
  averageTotal: number | null = null;
  totalAverageOutOf5: number = null;

  reviews: any[] = [];

  individualTotalAverageOutOf5: number[] = [];
  
  @Input() product_key: number = null;
  @Input() productName: string= null;
  @Output() totalAverageOutOf5Event: EventEmitter<number> = new EventEmitter<number>();
  @Output() reviewCountEvent: EventEmitter<number> = new EventEmitter<number>();
  @Input() imageObject: Array<{image:string, thumbImage: string}> = [];


  constructor(
    public dialog: MatDialog,
    private productHttp: ProductService,
    private appConstants: AppConstants,
    private commonService: CommanpageService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    (async()=>{
      if(changes['product_key']?.currentValue){
        await this.getHomeProductdetails();
        this.calculateAverageAppearance();
        this.calculateAverageInstallation();
        this.calculateAveragePrice();
        this.calculateAverageQuality();
        this.sendtotalAverageOutOf5();
      }
    })();
  }

  async getHomeProductdetails() {
    await this.getProductReviews();
  }

  openReviewItPopup(): void {
    const dialogRef = this.dialog.open(ReviewItPopupComponent, {
      // width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  calculateAverageAppearance(){
    const averageAppearance  = this.appearancesSum / this.appearances.length;
    if (averageAppearance !== null) {
      this.appearanceProgressBarValue = (averageAppearance / 5) * 100;
    }
  }

  calculateAverageInstallation(){
    const averageInstallation = this.installationsSum / this.installations.length;
    if (averageInstallation !== null) {
      this.installationProgressBarValue = (averageInstallation / 5) * 100;
    } 
  }

  calculateAveragePrice(){
    const averagePrice = this.pricesSum / this.prices.length;
    if (averagePrice !== null) {
      this.priceProgressBarValue = (averagePrice / 5) * 100;
    }
  }

  calculateAverageQuality(){
    const averageQuality = this.qualitysSum / this.qualitys.length;
    if (averageQuality !== null) {
      this.qualityProgressBarValue = (averageQuality / 5) * 100;
    }
  }

  calculateIndividualTotalAverageOutOf5(appearance: number, installation: number, price: number, quality: number){
    const maxScale: number = 5;
    const totalScale: number = 4 * maxScale;
    let totalReviewdSum = 0 

    if (totalScale !=0) {
      totalReviewdSum = (appearance + installation + price + quality) / totalScale;
    }
    const result = Number(Math.round(totalReviewdSum * maxScale));
    this.individualTotalAverageOutOf5.push(result);
  }

  discardAll(){
    this.appearances = this.installations = this.prices = this.qualitys = this.individualTotalAverageOutOf5 = this.product_photos = [];
    this.appearancesSum = this.installationsSum = this.pricesSum = this.qualitysSum = this.totalAverageOutOf5 = this.totalSum = this.averageTotal = null;
  }

  getProductReviews(product_key: number = this.product_key): Promise<string> {
    return new Promise<string>((resolve,reject)=>{
      this.productHttp.selectedProductReview(product_key).subscribe(
        {
          next:(response) => 
          {  
            if (response[this.firstObj]?.success == true) {
              const reviews = response[this.firstObj]?.data?.reviews || [];
              const photos = response[this.firstObj]?.data || [];
              for(let review of reviews){
                this.appearances.push(parseFloat(review.appearance));
                this.installations.push(parseFloat(review.installation));
                this.prices.push(parseFloat(review.price_value));
                this.qualitys.push(parseFloat(review.quality));
                this.reviews.push({
                  first_name: review.first_name || '',
                  last_name: review.last_name || '',
                  title: review.title || '',
                  comments: review.comments || '',
                  date: review.add_dt
                });
                this.sendTotalReviewsCount(this.reviews.length);
                this.calculateIndividualTotalAverageOutOf5(+review.appearance, +review.installation, +review.price_value, +review.quality);
              }
              this.appearancesSum = this.appearances ? this.commonService.arraySum(this.appearances) : 0.0;
              this.installationsSum = this.installations ? this.commonService.arraySum(this.installations) : 0.0;
              this.pricesSum = this.prices ? this.commonService.arraySum(this.prices) : 0.0;
              this.qualitysSum = this.qualitys ? this.commonService.arraySum(this.qualitys) : 0.0;              
              this.totalSum = (this.appearancesSum + this.installationsSum + this.pricesSum + this.qualitysSum);
              const totalLength = (this.appearances ? this.appearances.length : 0) + (this.installations ? this.installations.length : 0) + (this.prices ? this.prices.length : 0) + (this.qualitys ? this.qualitys.length : 0);
              this.averageTotal = totalLength != 0 ? (this.totalSum / totalLength): 0;
              
              const maxScale = 5;
              if (totalLength !== 0) {
                this.totalAverageOutOf5 =((this.averageTotal / maxScale) * 5);
              }else{
                this.totalAverageOutOf5 = 0;
              }

              this.product_photos = photos?.productPhotos;
              
            }
            else{
              this.totalAverageOutOf5 = 0;
            }
            resolve(`${this.totalAverageOutOf5}`);
          },
          error:(error) => 
          {
            //later log it into a file
            console.error('Custom Error: Unexpected Error in Product review:', error);
            reject(null);
          }
        });
    })
    
  }

  sendtotalAverageOutOf5(){
    this.totalAverageOutOf5Event.emit(this.totalAverageOutOf5);
  }

  sendTotalReviewsCount(count: number){
    this.reviewCountEvent.emit(count);
  }
}
