import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-product-option-errorpopup',
    templateUrl: './product-option-errorpopup.component.html',
    styleUrls: ['./product-option-errorpopup.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class ProductOptionErrorpopupComponent {


  constructor(    
    private dialogRef: MatDialogRef<ProductOptionErrorpopupComponent>
  ){}
  
  closeDialog(){
    this.dialogRef.close();
  }
}
