import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';
@Component({
    selector: 'app-warranty',
    templateUrl: './warranty.component.html',
    styleUrls: ['./warranty.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class WarrantyComponent {
  @Input() warrentiesData: any;
  
  product_key:any=0;

  constructor(){
    this.product_key = localStorage.getItem('selectedproductkey');
  }
}
