import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '../interfaces/http-response.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../app.constants';
import {
  ProductVariationSearchInterface,
  CommonInterface,
  ProductsSearchInterface,
  ProductsDataInerface } from '../interfaces/product.interface';
import { Common, ProductPhotosDataInterface } from '../interfaces/common.interface';
import { productByPartHashPostData, productByPartNamePostData } from '../interfaces/request-interfaces/productsearch.interface';
import { loveItPostDataInterface } from '../interfaces/request-interfaces/love-It-popup.interface';
import { wishlistPostDataInterface } from '../interfaces/request-interfaces/wishlist-popup.interface';
import { checkPromotionPostDataInterface, generateOrderKeyDataInterface, skuSlugPostDataInterface } from '../interfaces/request-interfaces/product.interface';
import { reviewsPostDataInterface } from '../interfaces/request-interfaces/review-It-popup.interface';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private endpoint: string = environment.apiUrl;
  private quantityArray: { [variation_key: number] : number } = null;
  private allProductDetailsInfoOfApplication: {[productKey:number]:any[]} = {};
  private masterProductDetailsInfoOfIncludedComponent: {[productKey: number]: any[]} = {};
  private productsDetailsBySize: {
    [productKey:number]: {
      [size:string]: any[]
    }
  } = {};
  firstObj: number = this.appConstants.firstObj;

  constructor(
    private http: HttpClient,
    private appConstants: AppConstants,
    ) {}

  productSettings(): Observable<any>{
    return this.http.get(this.endpoint+'products-option-setting')
  }

  productList(): Observable< Common<ProductsDataInerface<ProductPhotosDataInterface>> >{
    return this.http.get< Common< ProductsDataInerface<ProductPhotosDataInterface>> >(this.endpoint + 'products');
  }

  masterProductVariations(product_variation_key: number): Observable<any> {
    return this.http.get(
      this.endpoint + 'is_master_product-variations/' + product_variation_key
    );
  }

  productVariations(product_key: number,offset: number,data:{size: string}): Observable<any> {
    const apiUrl  = this.endpoint + 'product-variations-by-product-key/' + product_key
    return this.http.post(
     `${apiUrl}?offset=${offset}`
      // this.endpoint + 'product-variations-by-product-key/' + data
    ,data);
  }



  getProductPromotion(data: any): Observable<any> {
    return this.http.get(
      this.endpoint + 'product-promotion/' + data
    );
  }

  getSku(associated_product_key: number): Observable<any> {
    return this.http.get(
      this.endpoint + 'product-variations/' + associated_product_key
    );
  }

  getSkuSlug(data:skuSlugPostDataInterface){
    return this.http.post(this.endpoint + 'product-variations-slug',data)
  }

  getOptions(product_key: number): Observable<any>{
    return this.http.get(this.endpoint+'product-option-details/'+product_key);
  }

  selectedProductReview(product_key: number): Observable<any> {
    return this.http.get(
      this.endpoint + 'reviews/' + product_key
    );
  }

  singleProduct(product_key: number) {
    return this.http.get<HttpResponse>(this.endpoint + 'products/' + product_key,{priority:'high'} as any);
  }

  generateOrderKey(data: generateOrderKeyDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'orders', data);
  }

  checkPromotion(data: checkPromotionPostDataInterface): Observable<any>{
    return this.http.post<HttpResponse>(this.endpoint + 'promotions',data);
  }

  productDetailsContent(product_key: number): Observable<any>{
    return this.http.get<any>(this.endpoint+'product-details-content/'+product_key);
  }

  reviewIt(data: reviewsPostDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'reviews', data);
  }

  loveIt(data: loveItPostDataInterface): Observable<any> {
    return this.http.post('', data);
  }

  wishlist(data: wishlistPostDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'add-to-wishlist', data);
  }

  productByPartNameSearchResults(searchValue: string, product_name:string =""): Observable<ProductsSearchInterface[]| []> {
    if (searchValue !="") {
      const postData: productByPartNamePostData =
      {
        product_name:product_name
      }

      return this.http
      .post<Common<ProductsSearchInterface>>(this.endpoint+'products/'+ searchValue, postData)
        .pipe(
          map((Response: CommonInterface<ProductsSearchInterface>) => {
            if (Response[this.firstObj].success == true){
              return Response?.[this.firstObj]?.data
            }
          })
        )
    }
    else{
      return of([]);
    }
  }

  productByPartHashSearchResults(searchValue: string, product_name:string ="",sku:string="") : Observable<ProductVariationSearchInterface[] | []> {
    if(searchValue !=""){
      const postData: productByPartHashPostData = {
        product_name:product_name,
        sku:sku
      }
      return this.http
      .post<Common<ProductVariationSearchInterface>>(this.endpoint+'product-variations/'+ searchValue,postData)
        .pipe(
          map((Response: Common<ProductVariationSearchInterface> ) => {
            if (Response[this.firstObj]?.success == true){
              return Response?.[this.firstObj]?.data;
            }
          })
        )
    }
    else{
      return of([]);
    }
  }

  getProductRelationsByProductKey(productKey: number): Observable<any>{
    const url = `${this.endpoint}product-relations-by-product_key/${productKey}`;
    return this.http.get(url);
  }

  getRecentlyViewedProducts(product_key: number){
    return this.http.get(this.endpoint+'recently-viewed-products/'+product_key);
  }

   galaryphotos(product_key: number){
    return this.http.get(this.endpoint+'customer-gallery-photos/'+product_key);
  }

  getQuantityArray(): { [variation_key: number] : number } {
    return this.quantityArray;
  }

  setQuantityArrayValues(variation_key: number, qty: number) {
    this.quantityArray[`${{variation_key}}`] = qty;
  }

  initializeAllProductDetailsInfoOfApplication(product_key: number = null){
    this.allProductDetailsInfoOfApplication = {}
    if (product_key) {
      this.allProductDetailsInfoOfApplication[product_key] = [];
    }
  }

  setProductVariations(productKey: number,allProductDetailInfo: any[]){
    this.allProductDetailsInfoOfApplication[productKey] = allProductDetailInfo;
  }

  getProductVariations(productKey: number){
    const keys = Object.keys(this.allProductDetailsInfoOfApplication);

    if (keys.includes(String(productKey))){
      return this.allProductDetailsInfoOfApplication[productKey];
    }
    else{
      return [];
    }
  }

  initializeMasterProductsDetails(product_variation_key: number = null){
    this.masterProductDetailsInfoOfIncludedComponent = {};
    if (product_variation_key) {
      this.masterProductDetailsInfoOfIncludedComponent[product_variation_key] = [];
    }
  }

  setMasterProductsDetails(product_variation_key: number, masterProductDetails: any[]){
    this.masterProductDetailsInfoOfIncludedComponent[product_variation_key] = masterProductDetails;
  }

  getMasterProductsDetails(product_variation_key: number){
    const keys = Object.keys(this.masterProductDetailsInfoOfIncludedComponent);
    if (keys.includes(String(product_variation_key))) {
      return this.masterProductDetailsInfoOfIncludedComponent[product_variation_key];
    }
    else{
      return [];
    }
  }

  initializeProductVariationsBySize(product_key:number,selectedSize: string){
    this.productsDetailsBySize[product_key] = {};
    this.productsDetailsBySize[product_key][selectedSize] = [];
  }

  setProductVariationsBySize(product_key: number, selectedSize: string,productVariations: any[]){
    if (!this.productsDetailsBySize[product_key]) {
      this.productsDetailsBySize[product_key] = {};
    }
    this.productsDetailsBySize[product_key][selectedSize] = productVariations;
  }

  getProductDetailsBySize(product_key: number, selectedSize: string){
    const data = this.productsDetailsBySize[product_key]?.[selectedSize] || null;
    if (data) {
      return this.productsDetailsBySize[product_key][selectedSize]
    }
    else{
      return [];
    }
  }

  getPromotions(productKey: number | null, country_code: string = 'US') : Promise<any> {
    const data: checkPromotionPostDataInterface = {
      product_key: productKey,
      country_code: country_code
    }
    return new Promise<any>((resolve,reject) =>{
      this.checkPromotion(data).subscribe(
        {
          next:(response) =>
          {
            if(response[this.firstObj]?.success == true){
              resolve(response)
            }
            else{
              // console.log('Product Promotion Not Found');
              reject('promotions api response failed');
            }
          },
          error:(error: Error) =>
          {
            //later log it into a file
            // console.error('Custom Error: Unexpected Error in checking promotion key',error);
            reject('promotions api response failed');

          }
        }
      );
    })
  }

  fetchProductDetailsContent(product_key: number) : Promise<any>{
    return new Promise<any>((resolve,reject)=>{
      this.productDetailsContent(product_key).subscribe(
        {
          next:(response) =>
          {
            if (response[this.firstObj]?.success == true) {
              const data = response[this.firstObj]?.data;
              resolve(data);
            }
            else{
              console.error('Error in product details content api');
              reject(null);
            }

          },
          error:(error : Error) =>
          {
            //later log it into a file
            console.error('Custom Error: Unexpected Error while fetching content from product details content', error);
            reject(null);
          },
        }
      )

    })

  }




}
