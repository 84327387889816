import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgImageSliderModule } from "ng-image-slider";
import { NgFor } from "@angular/common";
import { AuthInterceptor } from "./app/service/auth.interceptor";

export const appConfig: ApplicationConfig = {
  providers:[
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule, 
      ReactiveFormsModule, 
      MatDialogModule, 
      NgxIntlTelInputModule, 
      NgbModule, 
      NgImageSliderModule, 
      NgbCarouselModule, 
      NgFor, 
      FormsModule
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),


  ]
}