import { Component, EventEmitter, Input, OnChanges,Output, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { environment } from 'src/environments/environment.prod';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-product-color',
    templateUrl: './product-color.component.html',
    styleUrls: ['./product-color.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
    ],
})
export class ProductColorComponent implements OnChanges {
  firstObj: number = this.appConstants.firstObj;
  domainImageBasePath: string = environment.domainImageBasePath;
  product_key: number;
  
  //color data
  uniqueColors: any[] = []

  @Input() productColor: any[]= [];
  @Input() selectedProductVariationKey: number = null;
  @Input() isSizeSelected: boolean = null;

  
  @Output() productVariationKeyChangeEvent: EventEmitter<number> = new EventEmitter<number>();


  constructor(private appConstants: AppConstants) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isSizeSelected']?.currentValue){
      if (this.isSizeSelected) {
        this.selectedProductVariationKey = null;
      }
    }
    if (changes['productColor']?.currentValue) {
      if(this.productColor.length > 0){
        this.getUniqueColors();
      }
    } 
  }

  OnSelectProduct(productVariationKey: number){
    if (this.selectedProductVariationKey != productVariationKey) {
      this.selectedProductVariationKey = productVariationKey;
      this.productVariationKeyChangeEvent.emit(this.selectedProductVariationKey)
    }
  }
  

  getUniqueColors(): void{
    this.uniqueColors = [];
    for (let color of this.productColor) {
      if (
        !this.uniqueColors.some((uniqueColor) => uniqueColor.color === color.color)
      ) {
        this.uniqueColors.push(color);
      }
    }
  }

  activeSkuClass(product_variation_key: number){
    if (!!this.selectedProductVariationKey) {
      if (this.selectedProductVariationKey = product_variation_key) {
        return true
      }
      else{
        return false
      }
    }
    else{
      return false;
    }
  }

}
