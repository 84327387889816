<div *ngIf="isloading">
  <app-product-details-skeleton></app-product-details-skeleton>
</div>

<section class="clearfix slideshow commonbg toppadding wickedtrucksMainSectionMargin bg-white">
  <div class="final_product_landing_section">

    <div class="final_product_new_design" *ngIf="!isloading">
      <div class="container top_container">
        <div class="row">
          <app-product-breadcrumb [productName]="productName" [selectedSku]="selectedSku" />
          <div class="main col-md-12">
            <div class="row">
              <div class="col-md-4">
                <div class="toplink">
                  <ul class="nav nav-pills" role="tablist" *ngIf="productVideo">
                    <li [class.active]="activeTab === 'image'">
                      <a (click)="toggleTab('image')" title="Image">
                        <i class="fa fa-camera pr-5"></i> Photo
                      </a>
                    </li>
                    <li [class.active]="activeTab === 'video'">
                      <a (click)="toggleTab('video')" title="Video">
                        <i class="fa fa-video-camera pr-5"></i> Video
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="tab-content clear-style" style="overflow:hidden;clear:both">
                  <div class="tab-pane active" id="pill-1" *ngIf="activeTab === 'image'">

                    <div class="owl-carousel content-slider-with-large-controls owl-theme" id="carousel"
                      style="opacity: 1; display: block;">
                      <div class="owl-wrapper-outer">
                        <div class="owl-wrapper" style="width: 716px; left: 0px; display: block;">
                          <div 
                          class="owl-item" 
                          style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          width: 358px;">
                            <div class="overlay-container_outer">
                              <div class="overlay-container_inner">
                                <div *ngIf="!isColorSelected else selectedColorImage" class="owl-item"
                                  style="width: 358px;">
                                  <section class="section light-gray-bg clearfix p-0 sf_slider_section">
                                    <ng-image-slider 
                                    #nav 
                                    [images]="imageObject" 
                                    [animationSpeed]="0.5"
                                    [infinite]="false" 
                                    [autoSlide]="1"
                                    [imageSize]="{width: '100%', height: '220'}" 
                                    slideImage="1"
                                    (arrowClick)="getNgImageSliderArrowClick($event)"
                                    >
                                    </ng-image-slider>
                                  </section>
                                </div>
                                <ng-template #selectedColorImage>
                                  <div *ngIf="!!colorChipImg  else colorchipAlterImage"
                                    class="overlay-container overlay-container_PLPhoto overlay-visible">
                                    <div class="overlay-container_outer">
                                      <div class="overlay-container_inner">
                                        <img class="display 1 lazyloaded" [src]="cdnImagePath+colorChipImg">
                                      </div>
                                    </div>
                                  </div>

                                  <ng-template #colorchipAlterImage>
                                    <div *ngIf="!!selectedColorChipAlter; else noImage"
                                      class="overlay-container overlay-container_PLPhoto overlay-visible">
                                      <div class="overlay-container_outer">
                                        <div class="overlay-container_inner">
                                          <img class="display 1 lazyloaded" [src]="cdnImagePath+selectedColorChipAlter">
                                        </div>
                                      </div>
                                    </div>
                                    <ng-template #noImage>
                                      <div class="overlay-container overlay-container_PLPhoto overlay-visible">
                                        <!--Displaying medium size image -->
                                        <div class="overlay-container_outer">
                                          <div class="overlay-container_inner">
                                            <img data-src="../../assets/images/picture_comming_soon.jpg"
                                              class="display 1 lazyloaded"
                                              src="../../../../../assets/images/category_landing_sf.png">
                                          </div>
                                        </div>
                                        <a href="../../../../../assets/images/category_landing_sf.png"
                                          class="overlay-link nopadding popup-img1" title="Picture Comming Soon">
                                          <i class="fa fa-search-plus"></i>
                                        </a>
                                      </div>
                                    </ng-template>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </div>
                            <span
                            *ngIf="(showMadeInUsaFlagSetting) && !isColorSelected"
                            class="usa-flag-span"
                            >
                            <div style="height: 42px; width: 70px;">
                              <img 
                              *ngIf="showUSAFlagForCurrentIndex"
                              style="display:inline" 
                              class=" ls-is-cached lazyloaded" 
                              alt="us-flag" 
                              src="../../../../../assets/images/mc_us_flag.png">
                            </div> 
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="activeTab === 'video' && sanitizedEmbedCode" class="video-container">
                    <div class="embed-responsive embed-responsive-16by9">
                      <div [innerHTML]="sanitizedEmbedCode"></div>
                    </div>
                    <!--<div class="icon_position_block">
                                            <img id="you_pl_img" src="http://mcleodracing-website.nebhub_dev.com/assets/images/play.png" class="icon_position" alt="play">
                                        </div>-->
                  </div>
                </div>

                <div class="product-cust-wra d-none d-lg-block d-md-block d-sm-block">
                  <p></p>
                  <div class="row">
                    <div class="col-md-4 col-md-offset-1 col-sm-4 col-sm-offset-1">
                      <img class="img-responsive center-block"
                        src="https://www.mcleodracing.com/assets/images/mcloed-product-cust-care.png">
                    </div>
                    <div class="col-md-7 col-sm-7">
                      <h4 class="text-uppercase">
                        have a question?</h4>
                      <h3 class="text-uppercase">
                        Ask a specialist.</h3>
                      <h2 class="text-uppercase">
                        <a href="tel:714-630-2764">(714) 630-2764</a>
                      </h2>
                      <div class="row mt-10">
                        <div style="display: inline-block;margin-right:10px">
                          <img class="img-responsive"
                            src="https://www.mcleodracing.com/assets/images/cbks-customer-img-third.png">
                        </div>
                        <div style="display: inline-block;margin-right:10px">
                          <h6>
                            In-House Experts
                          </h6>
                          <span>
                            We know our products
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <p></p>
                </div>
              </div>
              <div class="col-md-8 productinfo body_ico_wra">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pull-left">
                      <div [ngClass]="{'row': isMMYOn}">
                        <ng-container *ngIf="isMMYOn">
                          <div *ngIf="isMMYOn && !isMMYAvailable" class="col-md-3 col-xs-3"
                            style="padding-right:0;max-width:70px; ">
                            <ul class="social_icon_list" style="
                            padding: 0; 
                            width: auto; 
                            margin-right: 10px; 
                            display: inline-block;
                            position: relative;
                            bottom: 20px
                            ">
                              <li class="compare-list">
                                <i class="fa fa-close acc-icons1" style="background-color:#ec0008 !important;
                                padding: 12px 13px 10px;
                                border-radius: 100%;
                                color: #fff;
                                cursor: pointer;
                                font-size: 18px;
                                line-height: unset;
                                position: relative;
                                bottom: '20px'
                                "></i>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="isMMYOn && isMMYAvailable" class="col-md-3 col-xs-3"
                            style="padding-right:0;max-width:70px; ">
                            <ul class="social_icon_list" style="
                            padding: 0; 
                            width: auto; 
                            margin-right: 10px; 
                            display: inline-block;
                            position: relative;
                            bottom: 20px;
                            ">
                              <li class="compare-list">
                                <i class="fa fa-check acc-icons1" style="background-color: #3b5998 !important;
                                padding: 12px 13px 10px;
                                border-radius: 100%;
                                color: #fff;
                                cursor: pointer;
                                font-size: 18px;
                                line-height: unset;
                                
                                "></i>
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                        <div [ngClass]="{'col-md-9 col-xs-9': isMMYOn}"
                          [ngStyle]="{'padding-left': isMMYOn ? '0' : null}">
                          <h1 *ngIf="productName"
                            [ngStyle]="{'display': !isMMYOn  ? 'flex' : null , 'min-width' : (isMMYOn && isMMYAvailable && !showMMYNameSetting) ? 'max-content' : null}"
                            class="product_title tabtile-font" [title]="productName">
                            <ng-container *ngIf="isMMYOn">
                              <b *ngIf="!isMMYAvailable" style="font-family: 'DIN-Regular';">
                                {{mmyMessage}}
                              </b>
                              <ng-container *ngIf="showMMYNameSetting && isMMYAvailable else mmyNamerefbold">
                                {{vehicleName}}
                              </ng-container>
                              <ng-template #mmyNamerefbold>
                                <b *ngIf="!isMMYAvailable" style="font-family: 'DIN-Regular';">
                                  {{vehicleName}}
                                </b>
                              </ng-template>
                              <a *ngIf="isMMYOn && !isMMYAvailable" (click)="onClearSearchVehicle()"
                                class="pl-5 reviews_text" style="font-size: small;">
                                (Clear Your Vehicle)
                              </a>
                            </ng-container>
                            <br *ngIf="isMMYOn && !isMMYAvailable">
                            {{productName}}
                            <img *ngIf="isFreeShipping" src="../../../../../assets/images/mcleod_free_shipping.png"
                              alt="free_shipping">
                          </h1>
                          <ng-container *ngIf="isSkuActive && isOptionSelected  else noOptionPrice">
                            Part # {{selectedFinalSkuString}}
                          </ng-container>
                          <ng-template #noOptionPrice>
                            <div *ngIf="isSkuActive">
                              Part # {{ selectedSku }}
                            </div>
                          </ng-template>
                        </div>
                      </div>

                      <!-- </ng-template> -->
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="separator"></div>
                <input type="hidden" id="msrp_flag" value="0">
                <span class="promo-tag" style="margin-bottom: 10px; display: inline-block;" *ngIf="!!promotionDiscount">
                  <img src="../../../../../assets/images/transparent_pixel.gif" class="promo_img lazyloaded">
                  <a style="cursor:pointer; text-decoration:none;">
                    <span data-toggle="modal" data-target="#myModal-promotion-it8">
                      <span
                        *ngIf="!!promotionDescription && promotionDescription.length > 0 else nopromotionDescriptionref"
                        class="promo-tag-temp" style="padding-right: 10px;">
                        {{ promotionDescription }}
                      </span>
                      <ng-template #nopromotionDescriptionref>
                        <span class="promo-tag-temp">
                          &nbsp;
                        </span>
                      </ng-template>
                      <span class="sf_b_tag">
                        <span class="promo-per">
                          <span *ngIf="promotionType == 'discount_flat_rate'">
                            $
                          </span> {{promotionDiscount}}
                          <span *ngIf="promotionType == 'percentage_discount'">
                            %
                          </span> &nbsp;OFF
                        </span>
                      </span>
                    </span>
                  </a>
                </span>
                &nbsp;
                <input type="text" id="product_has_promotion_amount" value="20.00" style="display:none">
                <input type="text" id="product_has_promotion_type" value="percentage" style="display:none">
                <input type="text" id="product_has_promotion" value="1" style="display:none">
                <!-- <div class="product-price hidden-sm-pr hidden-md-pr">
                                    <h1 *ngIf="productsdetaildata" class="total_price total_price1">
                                        {{productsdetaildata}} </h1>
                                </div> -->
                <div class="star_inline" style="display: inline-block; margin-top: 0;">
                  <ng-container *ngIf="(totalAverageOutOf5 | starpipe:'fullStar') > 0;">
                    <ng-container *ngFor="let fullstar of (totalAverageOutOf5 | starpipe:'fullStar': true)">
                      <i class="fa fa-star full-star" aria-hidden="true"></i>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="(totalAverageOutOf5 | starpipe:'halfStar') > 0">
                    <ng-container *ngFor="let halfstar of (totalAverageOutOf5 | starpipe:'halfStar': true )">
                      <i class="fa fa-star-half-o half-star" aria-hidden="true"></i>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="(totalAverageOutOf5 | starpipe:'noStar') > 0">
                    <ng-container *ngFor="let noStar of (totalAverageOutOf5 | starpipe:'noStar': true)">
                      <i class="fa fa-star no-star" aria-hidden="true"></i>
                    </ng-container>
                  </ng-container>
                  <!-- <ng-template #allUnselected>
                                        <i class="fa fa-star no-star" *ngFor="let star of [1, 2, 3, 4, 5]" aria-hidden="true"></i>
                                    </ng-template> -->
                </div>
                <div class="mb-20" style="display: inline-block;">
                  <a class="pl-5 reviews_text" data-toggle="modal" data-target=".testimonial-review-149"
                    (click)="openReviewItPopup()">
                    <ng-container *ngIf="(reviewsCount != null && reviewsCount > 0) else reviewCountRef">
                      <ng-container *ngIf="reviewsCount > 1 else onereviewref">
                        ({{ reviewsCount }} reviews)
                      </ng-container>
                      <ng-template #onereviewref>
                        ({{ reviewsCount }} review)
                      </ng-template>
                    </ng-container>
                    <ng-template #reviewCountRef>
                      (Review it)
                    </ng-template>
                  </a>
                  <a class="wishlist text-dark" data-toggle="modal" data-target=".bs-example-modal-sm-149"
                    (click)="openLoveItPopup()">
                    <i class="fa fa-heart pl-10 pr-5"></i>Love it
                  </a>
                </div>
                <input type="hidden" id="product_type" value="bothp">
                <div *ngIf="showCompareProducts" class="check_box compare_box">
                  <input name="149" id="compare149" type="checkbox" class="css-checkbox">
                  <label for="compare149" class="css-label checkmark">Compare</label>
                </div>
                <div *ngIf="isSkuActive" style="display:inline-block">
                  <a class="my_wishlist" style="color:#5a5b64;display: inline-block;" (click)="openWishlist()">
                    &nbsp;<i class="fa fa-list-alt acc-icons" style="margin-right: 8px"></i>Wishlist</a>
                </div>
                <div class="hidden-xs cust-hide">
                  <ul class="social-links circle small clearfix margin-clear animated-effect-1">

                    <li>
                      <a (click)="share('facebook')" class="prettySocial_149"><i class="fa fa-facebook"></i></a>
                    </li>
                    <li>
                      <a (click)="share('twitter')" class="prettySocial_149"><i class="fa fa-twitter"></i></a>
                    </li>
                    <li>
                      <a (click)="share('linkedin')" class="prettySocial_149"><i class="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a (click)="share('pinterest')" class="prettySocial_149"><i class="fa fa-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div id="color-update-price-div" [ngStyle]="{'display': 'inline-block','width': '100%'}">
                  <div class="product-price disp-in" [ngStyle]="{'display': !!promotion ? 'inline-flex': null}">
                    <!-- <ng-container *ngIf="isOptionReplaceSelected && isSkuActive else otherOptionPrices">
                                            <h1
                                            style="display:block"
                                            class="hidden-xs total_price total_price1"
                                            >
                                                {{selectedReplaceSkuPrice | decimal}}
                                            </h1>
                                        </ng-container> -->
                    <!-- <ng-template
                                        #otherOptionPrices
                                        > -->
                    <ng-container *ngIf="(isSkuActive || variationCount == 1) && isOptionSelected ; else nonOption">
                      <h1 style="display:block" class="hidden-xs total_price total_price1">
                        ${{ selectedFinalPrice | decimal }}
                      </h1>
                    </ng-container>
                    <ng-template #nonOption>
                      <ng-container
                        *ngIf="(isSkuActive || variationCount == 1) && !!promotion && !isMapPricingPromo; else singlePrice">
                        <h1 style="display:block" class="hidden-xs total_price total_price1">
                          ${{ promotionalPrice | decimal }}
                        </h1>
                      </ng-container>
                      <ng-template #singlePrice>
                        <ng-container *ngIf="(isSkuActive || variationCount == 1); else priceRange ">
                          <h1 style="display:block" class="hidden-xs total_price total_price1">
                            ${{ selectedSkuPrice | decimal }}
                          </h1>
                        </ng-container>
                        <ng-template #priceRange>
                          <ng-container *ngIf="minPrice != maxPrice else maxminRef">
                            <h1 style="display:block" class="hidden-xs total_price total_price1">
                              ${{minPrice}} - ${{maxPrice}}
                            </h1>
                          </ng-container>
                          <ng-template #maxminRef>
                            <h1 style="display:block" class="hidden-xs total_price total_price1">
                              ${{samePrice}}
                            </h1>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                    <ng-container *ngIf="!!promotion && (isSkuActive || variationCount == 1) && !isMapPricingPromo">
                      <h1 style="display:block" class="hidden-xs total_price total_price1">
                        <s>
                          ${{selectedFinalNonPromotionalPrice | decimal}}
                        </s>
                      </h1>

                    </ng-container>
                    <!-- </ng-template> -->
                  </div>
                  <div *ngIf="true" class="p65_warning scroll_table map_pricing">
                    <a href="https://www.p65warnings.ca.gov/" target="_blank" class="btn btn-default">
                      <img src="../../../../../assets/images/p65_warnings_icon.png" class="img-responsive">
                      <span>
                        Warning:
                      </span> Cancer and Reproductive Harm - www.P65Warnings.ca.gov
                    </a>
                  </div>
                  <div *ngIf="(isSkuActive && isMapPricingPromo)" class="p65_warning scroll_table map_pricing">
                    <a href="" class="btn btn-default">
                      <img src="../../../../../assets/images/Warning-Arrow.png" class="img-responsive"> For Best Price
                      Add To Cart
                    </a>
                  </div>
                  <div style="display:inline-block;">

                    <div *ngIf="(isSkuActive || variationCount == 1)">
                      <a *ngIf="showAddToCartButton && ( inventoryflag == 1 && !isSelectedSkuInventoryAvailable)"
                        (click)="onAddToCart()">
                        <button class="add_to_cart 6 btn btn-success add_cart111" style="background-color: #5cb85c;">
                          PLACE BACK ORDER
                        </button>
                      </a>
                      <a *ngIf="(showAddToCartButton && ( inventoryflag == 0 || isSelectedSkuInventoryAvailable))"
                        (click)="onAddToCart()">
                        <button class="add_to_cart 6 btn btn-success add_cart111" style="background-color: #5cb85c;">
                          ADD TO CART
                        </button>
                      </a>

                      <a *ngIf="showCheckoutButton">
                        <button class="add_to_cart 6 btn btn-success add_cart111" style="background-color: #5cb85c;"
                          [routerLink]="['/cart']" [disabled]="disableCheckoutButton">
                          CHECKOUT NOW
                        </button>
                      </a>

                      <a *ngIf="showAddToCartForBestPriceButton">
                        <button class="add_to_cart 6 btn btn-success add_cart111" style="background-color: #5cb85c;">
                          ADD TO CART FOR BEST PRICE
                        </button>
                      </a>

                    </div>
                  </div>
                  <br>
                  <app-product-specifications
                    *ngIf="selectedSkuSpecifications.length > 0 && (isSkuActive || variationCount == 1)"
                    [selectedSkuSpecifications]="selectedSkuSpecifications" />
                </div>

                <!-- need to apply flexbox here -->
                <div id="cart_id" style="vertical-align: super; float:left"> </div>

                <div *ngIf="showViewApplications || showSearchByVehicleButtonSetting" class="product_buttons"
                  style="float: left;">
                  <a *ngIf="showSearchByVehicleButtonSetting" class="btn btn-danger text-uppercase scroll_table"
                    (click)="openSearchByVehicle()">
                    Search By Vehicle
                  </a>
                  <a *ngIf="showViewApplications" class="btn btn-danger text-uppercase scroll_table"
                    (click)="scrollToApplicationsTab()">
                    view applications
                  </a>
                </div>
                <div *ngIf="showQuestionsButtonSetting" class="question_btn"
                  [ngStyle]="{'vertical-align': 'super', 'float': isSkuActive ? 'left' : null}">
                  <a class="btn btn-animated btn-gray-transparent" (click)="onClickQuestions()">
                    QUESTIONS?
                    <i class="fa fa-send-o"></i>
                  </a>
                </div>
                <div *ngIf="showWhereToBuyButtonSetting" class="wheretobut_btn"
                  [ngStyle]="{'vertical-align': 'super', 'float':'left', 'padding-left': '5px', 'padding-right': '8px'}">
                  <a class="btn btn-animated btn-gray-transparent" [routerLink]="['/dealer-locator']">
                    WHERE TO BUY?
                    <i class="fa fa-eye"></i>
                  </a>
                </div>
                <div *ngIf="isSkuActive" class="instruction_btn">
                  <a class="btn btn-animated btn-gray-transparent">
                    INSTRUCTIONS
                    <i class="fa fa-download"></i>
                  </a>
                </div>
                <br>
                <div>
                  <div class="size_menu_strip" style="margin-bottom: 0px;" *ngIf="isSize">
                    <div *ngIf="isSize" class="filters">
                      <app-product-size *ngIf="isSize" [productSize]="productSize" [variationCount]="variationCount"
                        [selectedSize]="selectedSize" [isColorSelected]="isColorSelected"
                        [isOptionSelected]="isOptionSelected" (selectedProductSizeEvent)="onSelectSize($event)" />
                    </div>
                  </div>
                  <div *ngIf="isColor">
                    <div *ngIf="isColor" class="color_chip_codes filters">
                      <app-product-color [selectedProductVariationKey]="selectedProductVariationKey"
                        [productColor]="productColor" [isSizeSelected]="isSizeSelected"
                        (productVariationKeyChangeEvent)="onSelectColor($event)" />
                    </div>
                  </div>

                </div>
                <div class="row section_container" *ngIf="isOption && isSkuActive">
                  <app-product-option [productOptions]="productOptions" [isColorSelected]="isColorSelected"
                    [isSizeSelected]="isSizeSelected" [selectedProductVariationKey]="selectedProductVariationKey"
                    (sendOptionsDataEvent)="onSelectOptions($event)"
                    (productReplacementEvent)="onSelectProductReplacement($event)" />
                </div>
                <div *ngIf="showSkuDescriptionSetting && isSkuActive" class="product_desc" style="
                clear:both;
                padding: 10px 0 20px 0;
                ">
                  {{skuDescription}}
                </div>

                <div *ngIf="productDescription.length > 0" class="product_desc" style="clear:both">
                  <span style="
                                    font-size: 16px;
                                    caret-color: rgb(254, 254, 254);
                                    color: rgb(254, 254, 254);
                                    font-family: 'Din-regular';
                                    background-color: rgb(10, 10, 10);
                                    ">
                  </span>
                  <span style="
                                    font-size: 17px;
                                    color: #252525;
                                    caret-color: rgb(254, 254, 254);
                                    font-family: 'Din-regular';
                                    font-weight: 300;
                                    " [innerHTML]="productDescription">
                  </span>
                  <span style="
                                    font-size: 16px;
                                    caret-color: rgb(254, 254, 254);
                                    color: rgb(254, 254, 254);
                                    font-family: 'Din-regular';
                                    background-color: rgb(10, 10, 10);">
                    <span style="font-family:verdana,geneva,sans-serif;">
                      <span style="font-size:12px;"></span>
                    </span>
                  </span>
                  <span style="font-family:verdana,geneva,sans-serif;">
                    <span style="font-size:12px;"></span>
                  </span>
                  <br>
                </div>

              </div>
              <div class="product-cust-wra hidden-sm-pr hidden-md-pr">
                <p></p>
                <div class="row">
                  <div class="col-md-4 col-md-offset-1 col-sm-4 col-sm-offset-1">
                    <img class="img-responsive center-block"
                      src="./Red Line Transmission Fluids_files/mcloed-product-cust-care.png">
                  </div>
                  <div class="col-md-7 col-sm-7">
                    <h4 class="text-uppercase">have a question?</h4>
                    <h3 class="text-uppercase">Ask a specialist.</h3>
                    <h2 class="text-uppercase">
                      <a href="tel:714-630-2764">(714) 630-2764</a>
                    </h2>
                    <div class="row mt-10">
                      <div style="display: inline-block;margin-right:10px">
                        <img class="img-responsive"
                          src="./Red Line Transmission Fluids_files/cbks-customer-img-third.png">
                      </div>
                      <div style="display: inline-block;margin-right:10px">
                        <h6>In-House Experts</h6>
                        <span>We know our products</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 final_product_tabs_header">
        <div class="tabs-wrapper">
          <nav class="tabs">
            <ul class="nav nav-tabs text-center" role="tablist" #applicationsTab>
              <li *ngIf="isMaster && isSkuActive"
                [ngClass]="showMasterComponent ? 'tab after-slide is-active': 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showMasterComponent ? '#d8e2ee': null}">
                <a (click)="renderMasterComponent()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'width':'max-content',
                                    'right':'100px',
                                    'padding-right': '40px',
                                    'background-color': showMasterComponent ? '#d8e2ee': null
                                }">
                  Included Component
                </a>
              </li>
              <li *ngIf="(variationCount > 0 && !isSkuActive) && (showApplicationComponentSetting)"
                [ngClass]="showApplicationComponent ? 'tab after-slide is-active' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'10px','background-color': showApplicationComponent ? '#d8e2ee': null}">
                <a (click)="renderApplicationComponent()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'background-color': showApplicationComponent ? '#d8e2ee': null
                                }">
                  Applications
                </a>
              </li>
              <li *ngIf="(productDetailsInfoData?.length > 0) && (showProductDetailsInfoComponentSetting)"
                class="product_details_tab" style="display:block"
                [ngClass]="showProductDetailsInfoComponent ? 'tab after-slide is-active' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showProductDetailsInfoComponent ? '#d8e2ee': null}">
                <a (click)="renderProddetailsInfoComponent()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'background-color': showProductDetailsInfoComponent ? '#d8e2ee': null
                                }">
                  Product Details
                </a>
              </li>
              <li *ngIf="techTipsData?.length > 0" class="tech_tips_tab"
                [ngClass]="showTechTipsComponent ? 'tab after-slide ' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showTechTipsComponent ? '#d8e2ee': null}">
                <a (click)="renderTechTips()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'background-color': showTechTipsComponent ? '#d8e2ee': null
                                }">
                  Tech Tips
                </a>
              </li>
              <li *ngIf="faqsData?.length > 0" class="FAQS_tab"
                [ngClass]="showFAQsComponent ? 'tab after-slide ' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showFAQsComponent ? '#d8e2ee': null}">
                <a (click)="renderFAQS()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'background-color': showFAQsComponent ? '#d8e2ee': null
                                }">
                  FAQS
                </a>
              </li>
              <li *ngIf="warrentiesData?.length > 0"
                [ngClass]="showWarrentyComponent ? 'tab after-slide is-active' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showWarrentyComponent ? '#d8e2ee': null}">
                <a (click)="renderWarranty()" [ngStyle]="{
                            'cursor': 'pointer',
                            'background-color': showWarrentyComponent ? '#d8e2ee': null
                            }">
                  Warranty
                </a>
              </li>
              <li *ngIf="instructionsData?.length > 0"
                [ngClass]="showInstructionsComponent ? 'tab after-slide is-active' : 'tab after-slide'"
                [ngStyle]="{'margin-right':'5px','background-color': showInstructionsComponent ? '#d8e2ee': null}">
                <a (click)="renderInstructions()" [ngStyle]="{
                                    'cursor': 'pointer',
                                    'background-color': showWarrentyComponent ? '#d8e2ee': null
                                }">
                  Warranty
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <app-included-component *ngIf="showMasterComponent && isSkuActive"
    [selectedProductVariationKey]="selectedProductVariationKey" />

  <app-application *ngIf=" (showApplicationComponentSetting) && (showApplicationComponent && !isSkuActive)"
    [product_key]="product_key" [productName]="productName" [selectedSize]="selectedSize"
    [variationCount]="variationCount" [selectedProductVariationKey]="selectedProductVariationKey"
    [productPromotion]="productPromotion" [disableCheckoutButton]="disableCheckoutButton"
    (disdisableCheckoutButtonEvent)="getDisabledButton($event)"
    (sendSingleVariationDataEvent)="getSingleVariationPrices($event)" (sendSamePriceEvent)="getSamePrice($event)" />

  <app-product-detail-info
    *ngIf="(showProductDetailsInfoComponentSetting) && (showProductDetailsInfoComponent && productDetailsInfoData?.length > 0)"
    [productsdetailinfo]="productDetailsInfoData" />

  <app-tech-tips *ngIf="showTechTipsComponent && techTipsData?.length > 0" [techTipsData]="techTipsData" />

  <app-faqs *ngIf="showFAQsComponent && faqsData?.length > 0" [faqsData]="faqsData" />

  <app-warranty *ngIf="showWarrentyComponent && warrentiesData?.length > 0" [warrentiesData]="warrentiesData" />
  <div class="container-fluid"></div>

  <app-instructions *ngIf="showInstructionsComponent && instructionsData.length > 0"
    [instructionsData]="instructionsData"></app-instructions>

  <app-gallary-photos></app-gallary-photos>
  <app-recently-viewed [productKey]="product_key" />
  <app-related-products [productKey]="product_key" />
  <app-product-review (totalAverageOutOf5Event)="getTeotalAverageOutOf5($event)" [productName]="productName"
    [product_key]="product_key" [imageObject]="imageObject" (reviewCountEvent)="getTotalReviewsCount($event)" />

</section>

<app-subscribe />