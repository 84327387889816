import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { AppConstants } from 'src/app/app.constants';
import { CategoriesDataInterface } from 'src/app/interfaces/category.interface';
import { environment } from 'src/environments/environment.prod';
import { NgIf, NgClass, NgFor, KeyValuePipe } from '@angular/common';

@Component({
    selector: 'app-category-top-details',
    templateUrl: './category-top-details.component.html',
    styleUrls: ['./category-top-details.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, KeyValuePipe]
})
export class CategoryTopDetailsComponent implements OnInit, OnChanges{
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath: string = environment.cdnImagePath;
  categoriesdetail: CategoriesDataInterface;
  category_key: number = 0;
  filter_key: string = null;
  isFilterApplied: boolean = false;
  @Input() categoriesdetails: CategoriesDataInterface;
  @Input() categoryFilters: {[Number: string]: string} = null;
  @Input() subCategoriesCount: number;
  @Input() subProductsCount: number;
  @Input() universalProductsCount: number;

  @Output() applyFilterEvent: EventEmitter<string> = new EventEmitter<string>(); 
  @Output() applyShowAllEvent: EventEmitter<null> = new EventEmitter<null>(); 
  fallbackImage: string = '../../../../../../assets/images/category_landing_sf.png'; // Fallback image URL;
  mainImage: string;

  constructor(private appConstants: AppConstants){}

  ngOnInit(): void {
    this.category_key = +localStorage.getItem('selectedcategorykey');
  }

  ngOnChanges(changes: SimpleChanges): void {
    (async ()=>{
      if (changes?.['categoriesdetails']?.currentValue) {
        this.mainImage = await this.getSafeBackgroundImage();
      }
    })();
  }



  checkImageExists(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async getSafeBackgroundImage(): Promise<string> {
    const imageUrl = this.getBackgroundImage();
    const exists = await this.checkImageExists(imageUrl);
    return exists ? imageUrl : this.fallbackImage;
  }
  getBackgroundImage(): string {
    const imageUrl = this.categoriesdetails[this.firstObj]?.title_image;
    return imageUrl ? `${this.cdnImagePath}${imageUrl}` : this.fallbackImage;
  }
  
  onSelectFilter(filterKey: string){
    this.isFilterApplied = true;
    if (this.filter_key != filterKey) {
      this.applyFilterEvent.emit(filterKey);
    }
    this.filter_key = filterKey;
  }

  async onSelectShowAll(){
    if (this.filter_key) {
      this.applyShowAllEvent.emit(null);
    }
    this.isFilterApplied = false;
    this.filter_key = null;
  }

}
