import { Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.css'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class FaqsComponent {
  @Input() faqsData: any;
  
  product_key:any=0;

  isAccordionOpen = false;
  selectedQuestionId: string | null = null;
  answers: any[] = [];

  constructor(){}

  ngOnInit(): void {
    this.product_key = localStorage.getItem('selectedproductkey');
  }
   
  onQuestionClick(questionId: string){
    if (this.selectedQuestionId === questionId) {
      this.selectedQuestionId = null;
      this.answers = [];
    } else {
      this.selectedQuestionId = questionId;
    }
  }

}

