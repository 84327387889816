import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, firstValueFrom } from 'rxjs';
import { discountCodePostDataInterface, updateCartPostDataInterface } from '../interfaces/request-interfaces/cart.interface';
import { generateOrderKeyDataInterface } from '../interfaces/request-interfaces/product.interface';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private endpoint: string = environment.apiUrl;
  cartData = new EventEmitter<[] | []>();
  private validDiscountCode = '';
  firstObj: number = this.appConstants.firstObj;
  private orderItemList: {[product_variation_key: number]: number} = JSON.parse(localStorage.getItem('orderItemsList')) || {};

  constructor(
    private http: HttpClient,
    private appConstants: AppConstants,
  ) {}

  cartList(order_key: number) : Observable<any> {
    return this.http.get(this.endpoint + 'orders/' + order_key);
  }

  removeToCart(orderData: {
  order_key: number,
  order_item_key: number,
  product_variation_key: number,
  qty: number,
  price: number
}) {
  console.log('remove', orderData);

  const params = new HttpParams()
    .set('order_key', orderData.order_key)
    .set('order_item_key', orderData.order_item_key)
    .set('product_variation_key', orderData.product_variation_key)
    .set('qty', orderData.qty)
    .set('price', orderData.price);

  return this.http.delete(this.endpoint + 'orders/removeitem', { params });
}

  cart(order_key: number) {
    return this.http.get(this.endpoint + 'orders/' + order_key);
  }

  isValidDiscountCode(code: string): boolean {
    return code === this.validDiscountCode;
  }

  applydiscountCode(data: discountCodePostDataInterface) {
    const url = `${this.endpoint}discounts`;
    return this.http.post<any>(url, data);
  }

  removeCartDiscount(data: discountCodePostDataInterface) {
    const url = `${this.endpoint}discounts`;
    return this.http.post<any>(url, data);
  }

  // singlecart(data: any) {
  //   console.log('responsecartsingle', data);
  //   return this.http.get(this.endpoint + 'orders/cartdata' + data);
  // }

  updateCart(data: updateCartPostDataInterface ) {
    const url = `${this.endpoint}orders`;
    return this.http.put(url, data);
  }

  likeProductData(order_key: number): Observable<any> {
    return this.http.get(`${this.endpoint}product-you-may-like/${order_key}`);
  }

  ProductPromotionData(): Observable<any> {
    return this.http.get(`${this.endpoint}cart-promotion`);
  }

  addOrder(orderData: any): Promise<any> {
    return firstValueFrom (this.http.post<any>(this.endpoint + 'orders', orderData));
  }

  postCorporateCustomer(data: { email_address: string; type: string }): Observable<any> {
    return this.http.post(this.endpoint + 'corporate-customer', data);
  }

  militaryDiscount(formData: any): Observable<any> {
    console.log('postCorporateCustomer data', formData);
    return this.http.post<any>(this.endpoint + 'military-discount', formData);
  }

  getCartVerify(data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}verifycart`,  data );
  }

  verifyCart(order_key: number){
    return this.http.get(this.endpoint+'verifycart/'+order_key);
  }

  async generateOrderKey(data: generateOrderKeyDataInterface): Promise<null> {
    console.log('generateorderresponse:', data);
    return new Promise((resolve,reject)=>{
      this.http.post(this.endpoint + 'orders', data).subscribe(
        {
          next:(response) =>
          {
            if (response[this.firstObj]?.success== true) {
              const orderID = Number(response[this.firstObj]?.data?.order_key) || null;
              localStorage.setItem('orderId', String(orderID));
              resolve(null);
            }
            else{
              reject(null);
            }
          },
          error:(error: Error) =>
          {
            //later log it into a file
            // console.log('Custom Error: Unexpected Error in order key generation:', error);
            reject(null);
          }
        }
      );
    })
  }

  async addToCart(
    product: any | {product_key: number, product_variation_key: number, sku: string},
    order_key: number,
    qty: number,
    price:number = parseFloat(product.customer_price),          // code is change to receive customer price instead of actual price
    promotion_key:number=product.promotion_key,
    cart_promotion: boolean,
    product_option_key: string = ''
  ){

    const data = {
      order_key: order_key || "",
      product_key: Number(product.product_key),
      product_variation_key: parseInt(product.product_variation_key),
      sku: String(product.sku),
      qty: +qty,
      price: price,
      promotion_key:promotion_key,
      cart_promotion:cart_promotion,
      product_option_key: product_option_key || ''

    };
    await this.generateOrderKey(data);
    this.setOrderItemsList(+product.product_variation_key, qty);
    this.setLocalStorageOrderItemsList();
    const itemCount = +localStorage.getItem('ItemCount');
    localStorage.setItem('ItemCount',String(itemCount+qty));
    // this.router.navigate(['/cart']);
  }

  initializeOrderItemsList(){
    this.orderItemList = {};
  }

  setOrderItemsList(product_variation_key: number, numberOfItems: number){
    this.orderItemList[product_variation_key] = numberOfItems;
  }

  removeOrderFromOrderItemsList(product_variation_key: number){
    delete this.orderItemList[product_variation_key];
  }

  setLocalStorageOrderItemsList(){
    localStorage.setItem('orderItemsList',JSON.stringify(this.getOrderItemsList()));
  }

  getOrderItemsList(){
    const items = Object.keys(this.orderItemList);
    if (items.length > 0) {
      return this.orderItemList
    }
    else{
      return null;
    }
  }
  updateFreeShippingStatus(orderKey: number, isFreeShipping: boolean): Observable<any> {
    const payload = {
      order_key: orderKey,
      free_shipping: isFreeShipping
    };

    return this.http.put(this.endpoint + 'free-shipping', payload);
  }
}
