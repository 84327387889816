import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { loveItPostDataInterface } from 'src/app/interfaces/request-interfaces/love-It-popup.interface';
import { ProductService } from 'src/app/service/product.service';
import { environment } from 'src/environments/environment.prod';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-love-it-popup',
    templateUrl: './love-it-popup.component.html',
    styleUrls: ['./love-it-popup.component.css'],
    standalone: true,
    imports: [MatButtonModule, ReactiveFormsModule, NgxIntlTelInputModule, NgIf]
})
export class LoveItPopupComponent implements OnInit {

  product_key: number = null;
  isSubmitClicked: boolean = false;
  loveItForm: FormGroup;
  productsreviewdata: any;
  ProductName: string;
  cdnImagePath : string = environment.cdnImagePath;
  productImg: any;
  productDescription: string;
  baseUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private productHttp: ProductService,
    public dialogRef: MatDialogRef<LoveItPopupComponent>
  ) {

  }

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    this.product_key = +localStorage.getItem('selectedproductkey') || null;
    this.ProductName = localStorage.getItem('SelectedProductName') || null;
    this.productImg = localStorage.getItem('categoryImage');
    this.productDescription = localStorage.getItem('SelectedproductDescription');
    this.loveItForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      email_address: ['', [Validators.required, Validators.email]],
      comments: ['', Validators.required],
      product_key:  this.product_key,
      })
  }


  closePopup(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSubmitClicked = true;

    if(this.loveItForm.valid){
      this.isSubmitClicked = false;
      const data: loveItPostDataInterface = this.loveItForm.value;
      this.productHttp.loveIt(data).subscribe(
        {
          next:(response) =>
          {
            alert("Love it form submitted successfully!");
            this.loveItForm.reset();
          },
          error:(error) =>
          {
            this.isSubmitClicked = true;
          }
        }
      );
    }
    else{
      this.isSubmitClicked = true;
    }
  }

  share(platform: string) {
    const url = encodeURIComponent(`${this.baseUrl}/${this.ProductName}/p${this.product_key}`);
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `http://www.facebook.com/sharer.php?u=${url}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${this.productDescription}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${this.ProductName}`;
        break;
      case 'pinterest':
        shareUrl = `http://pinterest.com/pin/create/button/?url=${url}&media=${this.cdnImagePath}${this.productImg} &description=${this.productDescription}`;
        break;
    }

    window.open(shareUrl, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=300');
  }
}
