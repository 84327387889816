import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-sub-footer',
    templateUrl: './sub-footer.component.html',
    styleUrls: ['./sub-footer.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class SubFooterComponent implements OnInit {
  footerdata: any;
  menuLink: string = '';

  ngOnInit(): void {
    this.getFooterDataFromLocalstorage();
  }

  getFooterDataFromLocalstorage(): void {
    const getFooterData = localStorage.getItem('footerData');
    if (getFooterData) {
      try {
        this.footerdata = JSON.parse(getFooterData)?.socialIconsCopyright[3];
        if (this.footerdata) {
          this.menuLink = this.replaceYearWithCurrent(this.footerdata.menu_link);
        }
      } catch (error) {
        console.error('Error parsing Footer data from localStorage', error);
      }
    } else {
      console.warn('No footer data found in localStorage');
    }
  }

  replaceYearWithCurrent(menuLink: string): string {
    const currentYear = new Date().getFullYear();
    return menuLink.replace(/© \d{4}/, `© ${currentYear}`);
  }
}
